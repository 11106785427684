.footer {
    box-shadow: inset 0 0 10px rgba(1,22,30, .2);
    padding: 1rem 0 0 0;
    background: rgba(244,245,249,0.7);
    color: rgba(1,22,30, .7);

    @include breakpoint(large) {
        padding: 1.5rem 0 0 0;
    }
}

.footer__section {
    width: 100%;
    max-width: 100rem;
    margin: 0 auto;
}

.footer .cell {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}

.footer a {
    transition: color $base-transition-duration $base-transition-type;
    color: #4C84FF;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
        color: rgba(1,22,30, .7);
    }
}

.footer h3 {
    padding: 0 0 0.25rem 0;
    color: #4C84FF;
    font-size: 1.25rem;
    font-weight: 600;
    text-transform: uppercase;
}

.footer ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.footer li {
    padding: 0.25rem 0;
}

.footer svg {
    fill: #F15173;
}

.footer-info {
    padding: 1rem 0;
}

.footer__links a {
    position: relative;
    display: inline-block;
    padding: 0 0 0 1.1875rem;
    color: rgba(1,22,30, .7);

    &:before {
        transition: border-color 0.25s ease-in-out 0s;
        box-sizing: border-box;
        content: '';
        position: absolute;
        top: 52%;
        left: 0;
        transform: translateY(-50%) rotate(45deg);
        width: 0.5rem;
        height: 0.5rem;
        margin-top: -0.125rem;
        border-style: solid;
        border-color: #F15173;
        border-width: 1px 1px 0 0;
    }

    &:hover,
    &:focus, 
    &:active {
        color: #4C84FF;

        &:before {
           border-color: darken(#F15173, 20%);
        }
    }
}

.footer__bottom {
    padding: 1rem 0;
    border-top: 1px solid rgba(1,22,30, .15);
}

.footer__author {
    padding: 0.5rem 1.6875rem 0 1.6875rem;
    text-align: center;
    font-size: 0.875rem;

    a {
        color: #4C84FF;

        &:hover,
        &:focus,
        &:active {
            color: #01161E;
        }
    }
}