.tooltip {
    position: relative;
    display: inline-block;
    margin: 0 0 0 .5rem;
    cursor: help;   

    &:before {
        content: '?';
        display: inline-block;
        width: 1.125rem;
        height: 1.125rem;
        background: #0197F6;
        box-shadow: 1px 2px 0 rgba(0,0,0, 0.15);
        border-radius: 50%;
        color: #fff;
        text-align: center;
        font-size: 0.875rem;
        line-height: 1.25rem;
        font-weight: 700;
    }

    &:after {
        transition: all .65s cubic-bezier(.84,-0.18,.31,1.26);
        box-sizing: border-box;
        content: attr(aria-label);
        position: absolute;
        z-index: -100;
        left: 50%;
        bottom: 120%;
        transform: scale(0.6) translate(-50%, 50%);
        transform-origin: 0 50%;
        width: 18.75rem;
        margin: 0; 
        padding: 0.5rem 0.625rem;
        background: #0197F6;
        border-radius: 0.25rem;
        box-shadow: 1px 2px 0 rgba(0,0,0, 0.15);
        color: #fff;
        font-size: 0.875rem;
        line-height: 1.4; 
        opacity: 0;
    }   
}

.tooltip:hover::after,
.tooltip:focus::after {
    transform: scale(1) translate(-50%, 0);
    z-index: 100;
    opacity: 1;
}