.textBlok {
    padding-bottom: 1rem;
    color: #7d8284;

    h2 {
    	font-size: 2rem;
    }

    p {
    	font-size: 105%;
    	line-height: 1.5;
    }
}