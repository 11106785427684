.description {
    margin: 0 0 2rem 0;
    background: #fff;
}

.description .title_block {
	display: none;
}

.description .block {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.description .block_content {
	width: 100%;
	min-width: 100%;
}

.description #layered_form {
	width: 100%;
	display: flex;
	flex-direction: row;
}

.description #layered_form>div {
	width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	width: 100%;
	min-width: 100%;
}

.description #enabled_filters {
	display: none;
	margin: 0;
	padding: 0 20rem 0 0; 
	background: transparent;
	border: 0!important;
}

.description .layered_filter {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	padding: 0.5rem 1rem;

	@include breakpoint(medium) {
		flex-direction: row;
		align-items: center;
		width: 50%;
	}
}

.description ul {
	margin: 0;
	padding: 0;
}

.description .layered_subtitle {
	padding: 0 1rem 0 0; 
}

.description #layered_form select {
	width: auto;
	max-width: calc(100vw - 4rem);
	margin-bottom: 0;
	background: #fff;
}