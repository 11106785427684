
.bloktext {
    box-sizing: border-box;
	margin: 1rem 0;
    padding: 1rem;
    background: rgba(244,245,249,0.7);
    border: 1px solid rgba(0,0,0,0.075);

    @include breakpoint(large) {
    	margin: 2rem 0;
        padding: 2rem;
    }
}