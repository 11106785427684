body{
    overflow-x: hidden;
    width: 100%;
    background: $body-background;
    color: #01161E;

     
    @if ($body-background != false) {
        background: $body-background;
    }
    &.offCanvasOpened{
        height: 100vh;
        overflow: hidden;
        position: fixed;
        width: 100%;
    }
}
.wrapper{
    @if ($wrapper-shadow != false) {
        //@include xy-grid-container($global-width);
        //margin-left: auto;
        //margin-right: auto;
        //box-shadow: $wrapper-shadow;
    }
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    //@include breakpoint(xlarge) {
    //    padding-left: 100px;
    //}
}
.js-off-canvas-overlay{
    &, &.is-overlay-absolute, &.is-overlay-fixed{
        position: fixed;
        @include responsive-size(top, $h-height);
    }
}

.mainContent{
    box-sizing: border-box;
    width: 100%;
    max-width: 100rem;
    margin: 0 auto;
    padding: 0 0.9375rem;
    order: 3;
    background: $body-background;

    @include breakpoint(large) {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }

    @include breakpoint(xxlarge) {
        padding-left: 1.6875rem;
        padding-right: 1.6875rem;
    }

    >.grid-x {
        @include breakpoint(large) {
            flex-wrap: nowrap;
        }
    }


    .grid-padding-x>.off-canvas-content {
        padding-left: 0;
        padding-right: 0;
    }
}

.footer{
    position: relative;
    order: 5;
}

.off-canvas {
    @media screen and (min-width: 30rem) {
        top: 4.375rem!important;
    }

    @media screen and (min-width: 48.0625em) {
        top: auto!important;
    }
}

.grid-padding-x .grid-padding-x {
    margin-left: 0;
    margin-right: 0;
}

.off-canvas-content {
    margin: 0;
    padding: 0
}

.grid-x {
    @include breakpoint(large) {
        padding-top: 1rem;
    }
}

.section {
    @include breakpoint(large) {
        width: calc(100% - 19.5rem)!important;
    }
}

.index .section {
    @include breakpoint(large) {
        width: calc(100% - 19.5rem)!important;
    }    
}

.content {
    display: flex;
    flex-direction: column;

    @include breakpoint(large) {
        flex-direction: row;
        justify-content: space-between;
    }
}

//.main-content {
//    @include breakpoint(large) {
//        width: calc(100% - 19.375rem);
//    }
//}

//.index .main-content {
//    @include breakpoint(large) {
//        width: 100%;
//    }    
//}