.person {
    box-sizing: border-box;
    width: 100%;
    max-width: 52rem;
    margin: 2rem auto;
    padding: 2rem;
    background: #fff;

    @include breakpoint(large) {
        display: flex;
        flex-direction: row;
    }
}

.person__avatar {
    @include breakpoint(large) {
        width: 14rem;
        min-width: 14rem;
        max-width: 14rem;
        padding: 0 0 0 3rem;
    }
}

.person__main {
    @include breakpoint(large) {
        order: -1;
    }
}

.person__main h1 {
    @include breakpoint(large) {
        margin-top: 0;
    }   
}

.person__main .xl {
    padding-bottom: 1rem;
    color: #9b9b9b;
    font-size: 1.25rem;

    &:after {
        content: '';
        display: block;
        width: 100%;
        max-width: 13.125rem;
        height: 3px;
        margin: 1rem 0 0 0;
        background: #0675b2;
    }
}

.person__meta {
    padding: 1rem 0 0 0;

    ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        margin: 0;
        padding: 0.5rem 0;
        list-style: none;
    }

    li {
        padding: 0 1rem 0 0; 
    }

    a {
        font-size: 1rem;
    }
}