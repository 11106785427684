.button {
    transition: background-color 0.3s cubic-bezier(.2,.57,.73,.95) 0s, color 0.3s cubic-bezier(.2,.57,.73,.95) 0s;
}
.button,
.button.red {
	border-radius: 1.375rem;
    background: #F15173;
    color: #fff;
    text-decoration: none;

    svg {
    	fill: #fff;
    }

    &:hover,
    &:focus,
    &:active {
        background: darken(#F15173, 20%);
        color: #fff;

        svg {
        	fill: #fff;
        }        
    }
}


.button.primary {
    background: #4C84FF;
    color: #fff;

    svg {
        fill: #fff;
    }

    &:hover,
    &:focus,
    &:active {
        background-color: #395cc2;
        color: #fff;

        svg {
            fill: #fff;
        }        
    }
}