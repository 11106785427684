// TODO work on swiper settings - move them to project settings


.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    .galleryTop &{
        //@include responsive-size(height, $gallery-top-height);
    }
    .galleryThumbs &{
        //@include responsive-size(height, $gallery-thumbs-height);
    }
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    &:before,
    &:after {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
    }
    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    flex-shrink: 0;
    width: 100%;
    position: relative;
    text-align: center;
    justify-content: center;
    align-items: center;
    > div{
        display: flex;
    }
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }
    display: none;
    &.dragging img {
        pointer-events: none;
    }
    .slick-initialized & {
        display: flex;
    }
    .slick-loading & {
        visibility: hidden;
    }
    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-content{
    display: none;
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }
    &.dragging img {
        pointer-events: none;
    }
    .slick-initialized & {
        display: flex;
    }
    .slick-loading & {
        visibility: hidden;
    }
}
.slick-dots{
    width: 100%;
    text-align: center;
    margin-left: 0;
    @extend %baseMarginBottomThird;
    @extend %baseMarginTopThird;
    li{
        display: inline-block;
        border-radius: 100%;
        background: #4C84FF;
        text-indent: -999em;
        cursor: pointer;
        @include responsive-size(margin, $swiper-bullet-margin);
        @include responsive-size(width, $swiper-bullet-size);
        @include responsive-size(height, $swiper-bullet-size);
        button & {
            border: none;
            margin: 0;
            padding: 0;
            box-shadow: none;
            appearance: none;
        }
        &.slick-active{
            background: #F15173;
        }
    }
}
.slick-arrow.slick-hidden {
    display: none;
}
.slick-prev{
    position: absolute;
    display: block;
    overflow: hidden;
    margin-top: -1rem;
    width: $swiper-controls-arrow-size;
    height: $swiper-controls-arrow-size;
    top: 50%;
    left: 0.5rem;
    z-index: 10;
    cursor: pointer;
    transform: translateY(-$swiper-controls-arrow-size/2);
    outline: none;
    text-indent: 40px;
    background: #4C84FF;

    @include breakpoint(xxlarge up) {
        margin-top: - 2rem;
    }

    &:before{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        text-indent: 0;
        display: block;
        width: $swiper-controls-arrow-size;
        height: $swiper-controls-arrow-size;
        background: setColorAngle(#fff) center center;
        background-size: $swiper-controls-arrow-size $swiper-controls-arrow-size;
        transform: rotate(180deg);
    }
    &:hover{
        &:before{
            background: setColorAngle(#fff) center center;
            background-size: $swiper-controls-arrow-size $swiper-controls-arrow-size;
        }
    }
    &.swiper-button-disabled{
        visibility: hidden;
    }
}
.slick-next{
    @extend .slick-prev;
    left: auto;
    right: 0.5rem;
    &:before{
        transform: rotate(0deg);
    }
}

.galleryTop{
    position: relative;
    
    a {  
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 1 auto;
        width: 100%;
        max-height: 40rem; 

    }

    img {
        max-height: 40rem;         
    }

    .swiper-slide {
        align-items: stretch;
    }

    .swiper-slide-container{
        flex: 1 1 auto;
        align-items: center;
        flex-direction: row;
        display: flex;
    }
}

.galleryThumbs {
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 24rem;
    margin: 0 auto;
    padding: 0 2.5rem;

    .slick-slide {
        width: 25%;
        height: 5rem;
        opacity: $gallery-thumbs-inactive-opacity;
        padding: $gallery-thumbs-padding;
        box-sizing: border-box;
        align-items: stretch;
        cursor: pointer;
        outline: none;
        > div{
            padding: $gallery-thumbs-padding;
            flex: 1 1 auto;
            align-items: center;
            flex-direction: row;
            display: flex;
            position: relative;
            overflow: hidden;
        }

        img {
            width: auto;
            max-height: 5rem;
            margin: 0 auto;
        }
    }
    .slick-current{
        opacity: 1;
    }
    @include breakpoint(360px) {
        .slick-slide {
            width: 20%;
        }
    }
    @include breakpoint(medium up) {
        .slick-slide{
            width: 25%;
        }
    }
    @include breakpoint(550px) {
        .slick-slide {
            width: 20%;
        }
    }
    @include breakpoint(960px) {
        .slick-slide {
            width: 18%;
        }
    }
    @include breakpoint(xlarge up) {
        .slick-slide{
            width: 25%;
        }
    }
    @include breakpoint(xxlarge up) {
        .slick-slide{
            width: 20%;
        }
    }
}

.homepage-slider {
    margin: 1rem auto 0 auto;

    img {
        width: 100%;
    }

    .slick-slide {
        overflow: hidden;
    }

    .slick-slide > div {
        width: 100%;
    }

    .slick-content {

    }

    .slider__text {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        color: #fff;

        @include breakpoint(large) {
            max-width: 95rem;
            left: 50%;
            transform: translateX(-50%);
        }

        .copy {
            position: absolute;
            bottom: 0.5rem;
            right: 1rem;
            margin: 0;
            padding: 0.5rem 1rem;
            background: rgba(0,0,0, 0.6);
            color: #fff;
        }
    }

    .slider__article {
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        padding: 1rem;
        background: rgba(255,255,255, 0.7);
        text-align: center;

        @include breakpoint(large) {
            padding: 1rem 3rem 1.5rem 3rem;
        }

        p {
            font-size: 1rem;
        }

        .btn {
            display: inline-block;
            margin-top: 0.5rem;
            padding: 0.5rem 2rem;
            background: #F15173;
            border-radius: 1.375rem;
            color: #fff;
            font-weight: 700;
            font-size: 0.875rem;

            @include breakpoint(large) {
                margin-top: 1rem;
                font-size: 1rem;
            }
        }
    }

    h1 {
        margin-top: 0;
        color: #fff;
        font-weight: 300;
        font-size: 1.25rem;
        line-height: 1.2;

        @include breakpoint(large) {
            font-size: 2rem;
        }
    }

    .slider__article--dark {
        color: #000;

        h1 {
            color: #000;
        }
    }

    .slider__article--top-left {
        text-align: left;

        @include breakpoint(large) {
            top: 10%;
            left: 0;
            transform: none;
            width: 50%;
        }
    }

    .slider__article--top-center {
        text-align: center;

        @include breakpoint(large) {
            top: 10%;
            left: 50%;
            transform: translateX(-50%);
            width: 50%;
        }
    }

    .slider__article--top-right {
        text-align: right;

        @include breakpoint(large) {
            left: auto;
            top: 10%;
            right: 0;
            transform: none;
            width: 50%;
        }
    }

    .slider__article--center-left {
        text-align: left;

        @include breakpoint(large) {
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 50%;
        }
    }

    .slider__article--center-center {
        text-align: center;

        @include breakpoint(large) {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 50%;
        }
    }

    .slider__article--center-right {
        text-align: right;

        @include breakpoint(large) {
            left: auto;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            width: 50%;
        }
    }

    .slider__article--bottom-left {
        text-align: left;

        @include breakpoint(large) {
            top: auto;
            bottom: 10%;
            left: 0;
            transform: none;
            width: 50%;
        }
    }

    .slider__article--bottom-center {
        text-align: center;

        @include breakpoint(large) {
            top: auto;
            bottom: 10%;
            left: 50%;
            transform: translateX(-50%);
            width: 50%;
        }
    }

    .slider__article--bottom-right {
        text-align: right;

        @include breakpoint(large) {
            top: auto;
            left: auto;
            bottom: 10%;
            right: 0;
            transform: none;
            width: 50%;
        }
    }
}