.eshopMenu {
    margin: 0;
    padding: 0.125rem 0 0 0;
    list-style: none;
}

.eshopMenu li {
    border-bottom: 1px solid rgba(0,0,0,0.075);
}

.eshopMenu ul {
    margin: 0;
    padding: 0 0 0.5rem 0;
    list-style: none;
}

.eshopMenu li li {
    padding: 0 0 0 2rem;
    border-bottom: 0;
}

.eshopMenu a {
    transition: background-color 0.3s cubic-bezier(.2,.57,.73,.95) 0s, color 0.3s cubic-bezier(.2,.57,.73,.95) 0s;
    position: relative;
    display: block;
    padding: 0.75rem 1rem 0.75rem 2rem;
    color: #01161E;
    // /font-weight: 700;
    line-height: 1.2;
    text-decoration: none;

    &:before {
        transition: border-color 0.3s cubic-bezier(.2,.57,.73,.95) 0s;
        box-sizing: border-box;
        content: '';
        position: absolute;
        top: 52%;
        left: 0.75rem;
        transform: translateY(-50%) rotate(45deg);
        width: 0.5rem;
        height: 0.5rem;
        margin-top: -0.125rem;
        border-style: solid;
        border-color: #F15173;
        border-width: 1px 1px 0 0;
    }

    &:hover,
    &:focus {
        color: #4C84FF;

        &:before {
           border-color: darken(#F15173, 20%);
        }
    }
}

.eshopMenu li li a {
    padding: 0.25rem 1rem 0.25rem 0;
    color: rgba(37,37,37, 0.8);
    font-weight: 400;

    &:before {
        display: none;
    }
}