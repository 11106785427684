.comparison {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 100rem;
	margin: 0 auto;
	background: #fff;

	@include breakpoint(xlarge) {
		flex-direction: row;
		align-items: center;
	}
}

.comparison__fig {
	position: relative;
	box-sizing: border-box;
	width: 100%;
	padding: 1rem 0 0 0;

	@include breakpoint(xlarge) {
		width: 50%;
		padding: 0;
	}

	&:before,
	&:after {
		position: absolute;
		z-index: 2;
		bottom: 0.5rem;
		padding: 0.25rem 0.5rem;
		background: #fff;
		color: #000;
		line-height: 1;
		font-size: 0.75rem;
	}

	&:before {
		content: 'Před';
		left: 0.5rem;
	}

	&:after {
		content: 'Po';
		right: 0.5rem;
	}
}

.comparison__fig--full {
	width: 100%;
	padding: 0;

	@include breakpoint(xlarge) {
		width: 100%;
	}
}

.comparison__img {
	box-sizing: border-box;
	width: 100vw;
	padding: 1rem 0 0 0;

	@include breakpoint(xlarge) {
		width: 50%;
		padding: 0;
	}
}

.comparison__txt {
	box-sizing: border-box;
	width: 100%;
	padding: 1rem;
	order: -1;

	@include breakpoint(xlarge) {
		width: 50%;
		padding: 1rem 5%;
	}

	.mini {
		font-weight: 500;
		text-transform: uppercase;
	}

	h2 {
		font-size: 2rem;
		font-weight: 600;

		&:after {
			content: '';
			display: block;
			width: 206px;
			height: 3px;
			margin: 10px 0 20px 0;
			background: #ffde00;
		}
	}
}

.comparison__btn {
	transition: background-color 0.3s ease-in 0s, color 0.3s ease-in 0s;
	display: inline-block;
	vertical-align: top;
	margin: 1rem 0 0 0;
	padding: 0.5rem 1.5rem;
	background: #cd0b0b;
	border-radius: 1.25rem;
	color: #fff;
	text-decoration: none;
	font-family: $sub-font;
	font-weight: 700;

	&:hover,
	&:focus,
	&:active {
		background-color: #252525;
		color: #fff;
	}
}