.colors {
	display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
}

.colors__item {
	position: relative;
	margin: 0 1rem 1rem 0;
}

.colors__option {
	position: relative;
	z-index: 1;
	display: block;
	width: 1.375rem;
	height: 1.375rem;
	border-radius: 50%;

	&:before {
		transition: border-color 0.25s ease-in-out 0s;
		content: '';
		position: absolute;
		top: -4px;
		left: -4px;
		width: calc(100% + 8px);
		height: calc(100% + 8px);
		border-radius: 50%;
		border: 1px solid #e2e2e2;
	}

	&:after {
		transition: all .5s cubic-bezier(.84,-0.18,.31,1.26);
		content: attr(data-title);
		position: absolute;
		bottom: 1.2rem;
		left: 50%;
		transform: translateX(-50%);
		padding: 0.2rem 0.6rem;
		background: #1c1617;
		border-radius: 0.2rem;
		color: #fff;
		font-size: 0.8rem;
		opacity: 0;
		visibility: hidden; 
	}
}

.colors__item input {
	position: absolute;
	z-index: 2;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	opacity: 0;
	cursor: pointer;
}

.colors__item input:checked+.colors__option:before {
	border-color: #666;
}

.colors__item input:hover+.colors__option:after {
	bottom: 1.8rem;
	opacity: 1;
	visibility: visible;
}