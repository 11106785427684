.breadcrumbs {
    padding: 1rem 0;
    margin: 0;
    text-align: left;
}

.breadcrumbs li {
    position: relative;
    display: inline-block;
    vertical-align: top;
    float: none;
    padding: 0 1rem 0 0;
    font-weight: 700;

    &:after {
        display: none;
    }

    &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: -0.75rem;
        transform: translateY(-49%);
        width: 0.3125rem;
        height: 0.4375rem;
        margin-top: -1px;
        background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSI0OHB4IiBoZWlnaHQ9IjkzcHgiIHZpZXdCb3g9IjAgMCA0OCA5MyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgPHRpdGxlPmFycm93PC90aXRsZT4gICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+ICAgICAgICA8ZyBpZD0iYXJyb3ciIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuMDg1Nzg2LCAwLjA4NTg3NikiIGZpbGw9IiMyQTNFOTIiIGZpbGwtcnVsZT0ibm9uemVybyI+ICAgICAgICAgICAgPHBvbHlnb24gaWQ9IkxpbmUtMyIgcG9pbnRzPSI4LjkyNjE0MzYyZS0wNSAxLjM5ODIzNTI2IDEuNDMwMTAyNiAwIDQ2LjgyODMzNzkgNDYuNDMwMDEzMyA0NS4zOTgzMjQ1IDQ3LjgyODI0ODYiPjwvcG9seWdvbj4gICAgICAgICAgICA8cG9seWdvbiBpZD0iTGluZS0zLUNvcHkiIHBvaW50cz0iNDUuNDE0MjEzNiA0NC45OTk5MTA3IDQ2LjgyODQyNzEgNDYuNDE0MTI0MyAxLjQxNDIxMzU2IDkxLjgyODMzNzkgMCA5MC40MTQxMjQzIj48L3BvbHlnb24+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=);
        background-size: 0.3125rem 0.4375rem;
    }

    &:first-child {
        &:before {
            display: none;
        }
    }
}

.breadcrumbs a {
    font-weight: 400;
    text-decoration: underline;

    &:hover,
    &:focus, 
    &:active {
        text-decoration: none;
    }
}