div.product {
    padding: 0;
}

.product__meta {
	align-items: center;
	justify-content: center;
	margin: 1rem 0;
	padding: 0.5rem;
	background: #eff2f6;
	line-height: 1;
	text-align: left;
}

.product__top {
	position: relative;
	display: flex;
	flex-direction: column;
	background: #fff;
	margin: 0 0 2rem 0;
	padding: 1rem 0;

	@include breakpoint(xxlarge) {
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;
	}
}

.product__fig {
	box-sizing: border-box;
	padding: 0 0 1rem 0;
	box-shadow: 0 1px 4px 0 rgba(0,0,0, 0.2);

	@include breakpoint(xxlarge) {
		order: -1;
		width: 28.125rem;
		min-width: 28.125rem;
		max-width: 28.125rem;
	}

	.thumbnails__list__arrow__left {
		@media only screen and (max-width: 470px) {
			left: -140px;
		}
	}

	.thumbnails__list__arrow__right {
		@media only screen and (max-width: 470px) {
			left: 110px;
		}
	}
}

.product__main {
	box-sizing: border-box;

	@include breakpoint(xxlarge) {
		flex: auto;
		padding-left: 5%;
	}

	.headline {
		margin-top: 0;
		padding-bottom: 1rem;
		font-size: 2rem;
	}

	.headline:after {
		margin-left: 0;
		margin-right: 0;
	}
}

.product__desc {
	box-sizing: border-box;
	padding: 2rem 2rem 0 2rem;

	@include breakpoint(xxlarge) {
		width: 48%;
		padding: 0 5%;
	}
}

.product__feature {
	display: inherit!important;
}

.product__feature--snd {
	padding: 1rem 0 0 0; 
}

.product__list {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding: 0.5rem 0 0 0;

	p {
		flex: auto;
		width: calc(50% - 1.5rem);
		margin: 0 1.5rem 1rem 0;
		padding: 0 0 0.5rem 0;
		border-bottom: 1px solid #e0e0e0;
		font-size: 0.75rem;
		text-transform: uppercase;
		line-height: 1.2;
	}

	span {
		display: block;
		padding: 0.25rem 0 0 0;
		font-weight: 700;
		font-size: 0.9rem;
		text-transform: none;
	}
}

.product__attribute {
	display: inherit!important;
    padding: 0 0 1rem 0;

    h2 {
        margin: 0!important;
        padding: 0;
        color: #7d8284;
        font-weight: 500;
        font-size: 13px;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 15px;
    }

    li {
        display: inline-block;
        vertical-align: top;
        padding: 0;
        color: #000;
    }
}

.product_table {
	width: 100%;

	tbody tr:nth-child(even) {
		background: transparent;
	}

	th {
		border-bottom: 3px solid #f5f5f5;
		text-align: center;

		&:nth-of-type(1) {
			text-align: left; 
		}
	}

	td {
		padding: 0 .625rem;
		border-bottom: 3px solid #f5f5f5;
		text-align: center;

		&:nth-of-type(1),
		&:nth-of-type(2) {
			text-align: left; 
		}
	}

	img {
		width: 7rem;
		min-width: 7rem;
	}

	.productList__link {
		text-decoration: none;

		h2 {
			@extend %header3FontSize;
			line-height: 1.2;
		}
	}
}

.product__row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 0.5rem 0;

	p {
		margin: 0;
		padding: 0;
	}

	.xl {
		font-size: 1.125rem;
	}
}

.product__info {
	text-align: right;

	select {
		margin: 0;
	}
}

.product__btns {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 0.5rem 0 1rem 0;

	.button{
		margin: 0;
	}

	.ajax_add_to_cart_button {
		width: 50%;
	}

	.productCounter {
		margin: 0;
	}
}

.produkt_detail_blok {
	position: relative;
}

.produkt__cena_sdph {
	font-weight: 600;
}

.product__prologue {
	position: relative;
	margin: 0 0 1rem 0; 
}

.product__link {
	position: absolute;
	z-index: 3;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	display: inline-block;
	color: #0197F6;
	text-align: center;
}

.product__size {
	position: relative;
	display: inline-block;
	padding: 0 0 0 1.5rem;

	svg {
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		width: 1rem;
		height: auto;

		path {
			fill: #cd0b0b;
		}
	}
}