.header {
    position: relative;
    z-index: 21;
    background: #fff;
    width: 100%;
    margin: 0 auto;
    color: #505A5B;
}

.header__container {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    height: 4rem;
    max-width: 100rem;
    margin: 0 auto;

    @include breakpoint(large) {
        height: 4rem;
        align-items: center;
    }
}

.logo {
    position: relative;
    z-index: 12;
    display: flex;
    flex: 0 1 auto;
    align-items: center;
    padding: 0 0 0 1rem;
    
    @include breakpoint(large) {
        padding: 0 0 0 2rem;
    }

    a {
        display: inline-block;
        max-width: 100%;

        &:hover,
        &:focus,
        &:active {
            outline: none;
        }
    }
    
    img {
        max-width: 100%;
        display: block;
    }
}

.logo__text {
    position: absolute;
    top: 50%;
    left: 170px;
    transform: translateY(-50%);
    display: none;
    color: #fff;
    white-space: nowrap;

    @include breakpoint(xxlarge) {
        display: block;
    }
}



.shopTools {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @include breakpoint(large) {
        padding-right: 2rem;
    }
    
    ul {
        list-style: none;
        margin: 0;
        max-width: 100%;
        display: flex;
        align-items: center;
        flex: 1 1 auto
    }

    li {
        flex: 0 1 auto;
        display: flex;
        flex-direction: row;
        margin: rem-calc(0 0 0 5);

        &.hamburger{
            width: auto;
            
            @include breakpoint(large) {
                display: none;
            }

            span {
                display: flex;
                align-self: center;
                cursor: pointer;
                font-weight: $h-button-hamburger-font-weight;
                padding: $h-buttons-text-padding;
                padding-left: 0;
            }

        }
      
    }
    
    a {
        transition: color $base-transition-duration $base-transition-type;
        display: flex;
        color: #505A5B;
        flex: 1 1 auto;
        justify-content: center;
        align-items: center;
        position: relative;
        text-decoration: none;

        &:hover,
        &:focus,
        &:active {
            color: #4C84FF;

            svg {
                fill: #4C84FF;
            }
        }
    }

    .search__close {
        position: absolute;
        top: 0;
        right: 0;
    }

    svg {
        transition: fill $base-transition-duration $base-transition-type;
        width: 2rem;
        height: 2rem;        
        fill: #505A5B;

        @include breakpoint(large) {
            width: 2.25rem;
            height: 2.25rem; 
        }
    }

    .badge {
        box-sizing: border-box;
        position: absolute;
        top: 0;
        left: 1.25rem;
        display: block;
        width: 1.25rem;
        min-width: 1.25rem;
        height: 1.25rem;
        margin: 0;
        padding: 0;
        line-height: 1.25rem;
        text-align: center;
        font-size: 0.75rem;
    }  

    .textLabel {
        display: none;
        font-size: 0.9375rem;

        @include breakpoint(large) {
            display: block;
        }
    } 
    
}

.search__overlay {
    transition: all 0.5s ease-in-out 0s;
    box-sizing: border-box;
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 4.375rem;
    background: rgba(0,0,0, 0.6);
    opacity: 0;
    visibility: hidden;

    @include breakpoint(large) {
        padding-top: 8.875rem;
    }
}

.search-active .search__overlay {
    opacity: 1;
    visibility: visible;
    z-index: 30;
}

.search__form {
    position: relative;
    width: 90%;
    max-width: 45.625rem;
    margin: 2.5rem auto 0 auto;
    background: #fff;

    @include breakpoint(large) {
        top: 5rem;
    }
}

.search__input {
    box-sizing: border-box;
    width: 100%;
    height: 3rem;
    margin: 0;
    padding: 0 3rem 0 1rem;
    background: transparent;
    border: 0;
    font-size: 1rem;
    outline: none;

    &:focus {
        box-shadow: none;
        border: 0;
    }

    @include breakpoint(large) {
        height: 3.75rem;
        padding: 0 3.75rem 0 1rem;
        font-size: 1.5rem;
    }
}

.search__close {
    position: absolute;
    top: 0;
    right: 0;
    width: 3rem;
    height: 3rem;

    @include breakpoint(large) {
        width: 3.75rem;
        height: 3.75rem;
    }

    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 1.25rem;
        height: 1.25rem;
        fill: #000;

        @include breakpoint(large) {
            width: 1.875rem;
            height: 1.875rem;
        }
    }
}