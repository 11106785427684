.banners {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100rem;
    margin: 0 auto;
    padding: 3rem 0;

    @include breakpoint(large) {
        flex-direction: row;
    }
}

.banners__link {
    position: relative;
    overflow: hidden;
    display: block;

    img {
        will-change: transform;
        -webkit-transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
        -moz-transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
        -o-transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    &:hover,
    &:focus,
    &:active {
        img {
            transform: scale(1.05);
        }
    }
}