html {
    font-family: $baseFontFamily;
}

h1, h2, h3, h4{
    font-family: $sub-font;
}

h1{
    @extend %header1FontSize;
    @extend %baseMarginTopHalf;
    margin-top: 0;
    font-weight: 500;
    color: $header1-color;
}

h2{
    @extend %header2FontSize;
    //font-weight: $header2-font-weight;
    color: $header2-color;
   
    &.likeH1{
        @extend h1;
    }

    &.recommend {
        @extend h1;
        text-align: center;   
    }
}

h3{
    @extend %header3FontSize;
    color: $header3-color;
}
a {
    transition: background-color 0.3s cubic-bezier(.2,.57,.73,.95) 0s, color 0.3s cubic-bezier(.2,.57,.73,.95) 0s;
    color: #4C84FF;
    text-decoration: underline;

    &:hover,
    &:focus,
    &:active {
        color: #395cc2;
        text-decoration: none;
    }
}
p {
    @extend %normalFontSize;
    @extend %baseMarginBottomFourth;
}
label{
    @extend %normalFontSize;
}
ul{
    @extend %normalFontSize;
    @extend %baseMarginBottomHalf;
}
hr{
    @extend %baseMarginBottomFourth;
    @extend %baseMarginTopFourth;
}
table{
    @extend %normalFontSize;
    @extend %baseMarginBottomHalf;
    th{
        text-align: left;
    }
    a{
        text-decoration: underline;
        &:hover{
            text-decoration: none;
        }
    }
}
blockquote{
    @extend %baseMarginBottomHalf;
}
.media-object{
    margin: 0;
    .media-object-section{
        @extend %baseMarginBottomHalf;
    }
    &.stack-for-small{
        .media-object-section{
            padding-bottom: 0;
        }
    }
}
.table-scroll{
    @extend %baseMarginBottomHalf;
    table{
        margin-bottom: 0;
    }
}
.responsive-embed{
    @extend %baseMarginBottom;
}
.textSmaller{
    @extend %smallFontSize;
}
.textBigger{
    @extend %bigFontSize;
}
.text-striked{
    text-decoration: line-through;
}
.redText{
    color: #000;
}
.primaryText{
    color: map_get($foundation-palette, primary);
}
.secondaryText{
    color: map_get($foundation-palette, secondary);
}
.successText{
    color: map_get($foundation-palette, success);
}
.greenText{
    @extend .successText;
    &:hover{
        @extend .successText;
    }
}
.block-cms{
    a{
        color: map_get($foundation-palette, secondary);
        text-decoration: underline;
        &:hover{
            text-decoration: none;
        }

    }
}
.print{
    color: $printerColor;
    svg{
        fill: $printerColor;
    }
    &:hover{
        color: darken($printerColor, 10%);
    }
}
.facebookLink{
    color: $faceBookColor;
    svg{
        fill: $faceBookColor;
    }
    &:hover{
        color: darken($faceBookColor, 10%);
    }
}

.headline {
    font-size: 1.5rem;
    line-height: 1.2;

    @include breakpoint(large) {
        font-size: 3.125rem; 
    }

    &:after {
        content: '';
        display: block;
        width: 2.25rem;
        height: 0.25rem;
        margin: 1rem auto 1rem auto;
        background: #e0e0e0;
    }

    @include breakpoint(large) {
        margin: 1rem auto 2rem auto;
    }
}

.headline--center {
    margin-bottom: 0;
    text-align: center;
}

.headline--snd {
    margin-bottom: 0; 

    &:after {
        display: none;
    }
}

.media-object-section+.media-object-section {
    padding-left: 2rem;
}

.hide_mobile {
    @media (max-width: 47.9375em) {
        display: none;
    }
}

#cookieNotice {
    a {
        color: #de0025;
    }
}

#layer_cart_product_title {
    color: #000!important;
}

.download a {
    display: inline-block;
    padding: 0.5rem 1rem;
    background: #e0f5ff;
    border: 1px dashed #a3e2ff;
    text-decoration: none;
}

.text__skladem {
    color: #0197F6;
}

.text__skladem2 {
    color: #0197F6;
}

.text__skladem2.greenText {
    color: #5CAA00;
}

.text__skladem.greenText {
    color: #5CAA00;
}

