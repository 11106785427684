.badge.alert {
    background: #F15173;
    color: #fff;
}

.button.bt_compare {
	background: #007CC1;
	color: #fff;

	&:hover,
	&:focus,
	&:active {
		background-color: #007CC1;
		color: #fff;
	}
}

.button.bt_compare[disabled] {
	opacity: 0.8;
	pointer-events: none;
}