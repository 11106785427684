.tabs {
    border: 0;
}

.tabs-content {
	margin: 0 0 2rem 0;
	background: rgba(244,245,249,0.7);

	iframe,
	img {
		display: block;
		margin: 1rem auto;
	}

	.video-wrap {
		max-width: 60.8125rem;
	    margin: 0 auto;
	}

	.video {
	    position: relative;
	    overflow: hidden;
	    margin: 0 0 1rem 0;
	    padding-bottom: 56.25%;
	    height: 0;

	    iframe,
	    object,
	    embed,
	    video {
	        position: absolute;
	        top: 0;
	        left: 0;
	        width: 100%;
	        height: 100%;
	    }
	}
}

.accordion-content {
	iframe,
	img {
		display: block;
		margin: 1rem auto;
	}

	.video-wrap {
		max-width: 60.8125rem;
	    margin: 0 auto;
	}

	.video {
	    position: relative;
	    overflow: hidden;
	    margin: 0 0 1rem 0;
	    padding-bottom: 56.25%;
	    height: 0;

	    iframe,
	    object,
	    embed,
	    video {
	        position: absolute;
	        top: 0;
	        left: 0;
	        width: 100%;
	        height: 100%;
	    }
	}
}

.tabs-cols {
	display: flex;
	flex-direction: column;

	@include breakpoint(large) {
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
	}
}

.tabs-col {
	padding: 0 0 1rem 0;

	@include breakpoint(large) {
		width: calc(50% - 1rem);
	}

	h2 {
		padding-top: 0;
	}
}

.tabs__nav {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-star;
	width: 100%;
	max-width: 100rem;
	margin: 0 auto;
	padding: 1rem 0 0 0; 
	list-style: none;

	li {
		font-size: 1.2rem;
		padding: 0;
		font-weight: 500%;
		font-family: $sub-font;
		font-size: 0.9375rem;
	}

	a {
		display: block;
		background: rgba(255,255,255, 0.3);
		padding: 0.75rem 1rem;
		border-radius: 3px 3px 0 0;
		color: #1c1617;
		text-decoration: none;

		&:hover {
			color: #0197F6;
		}
	}

	.is-active a {
		background-color: #fff;
		color: #0197F6;
	}
}

.tabs-title>a:focus,
.tabs-title>a[aria-selected='true'] {
	background: rgba(244,245,249,0.7);
	text-decoration: none;
}

.tabs__fragment {
	position: relative;
	top: auto;
	left: auto;
	padding: 0 0 3rem 0;
}

.tabs__fragment.is-hide {
	position: absolute;
	top: -999em;
	left: -999em;
}

.tabs__fragment--snd {
	margin: 0 0 2rem 0;
	padding: 2rem;
	background: #fff;
}

.tabs__article {
	padding: 2rem 0 1rem 0;
}

.tabs--home {
	max-width: 75rem;
	margin: 0 auto 4rem auto;
	background: rgba(244,245,249,0.7);
	box-shadow: 0 1px 4px 0 rgba(0,0,0, 0.2);

	.tabs__nav {
		position: relative;
		z-index: 1;
		width: 100%;
		padding: 0;
		background: rgba(255,255,255, 0.3);
		border-radius: 3px 3px 0 0;

		&:before {
			content: '';
			position: absolute;
			z-index: 1;
			bottom: -1px;
			left: 0;
			width: 100%;
			height: 1px;
			box-shadow: 0 0 10px rgba(0,0,0, 0.7);

		}

		li {
			position: relative;
			z-index: 2;
		}

		a {
			background: transparent;
		}

		.is-active a {
			background-color: #fff;
		}
	}	

	.tabs__fragment {
		padding-bottom: 0;
	}

	.comparison {
		position: relative;
		z-index: 2;
	}
}