.products_block-comparison {
    overflow: auto;
}

#product_comparison {
    .product-image-block {
        width: 100%;
        max-width: 250px;
        margin: 0 auto;
    }

    .product_image {
        position: relative;
        display: block;
        padding-bottom: 100%;

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .cmp_remove {
        position: relative;
        float: right;
        display: block;
        width: 20px;
        height: 20px;

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 9px;
            left: 0;
            width: 100%;
            height: 2px;
            background: #ff0000;
        }

        &:before {
            transform: rotate(45deg);
        }

        &:after {
            transform: rotate(-45deg);   
        }
    }

    tr {
        background: transparent;
    }

    td {
        width: 25%;
        border-top: 1px solid #f1f1f1;
        color: #252525;
    }

    .comparison_infos {
        text-align: center;
    }

    .feature-name {
        color: #747474;
    }

    .button {
        margin-bottom: 0.5rem;
    }
}