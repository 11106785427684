
.blackbox {
	margin: 1rem 0;
    padding: 1rem;
    background: #2f2f2f;
    color: #fff;

    @include breakpoint(large) {
    	margin: 2rem 0;
        padding: 2rem;
    }
}

.blackbox h1,
.blackbox h2,
.blackbox h3,
.blackbox h4,
.blackbox h5,
.blackbox h6 {
    color: #fff;
}

.blackbox a {
	color: #fff;
}