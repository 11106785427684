.productbox {
    overflow: hidden;
    margin: 1rem 0;
    padding: 1rem;
    background: rgba(76,132,255, .2);

    @include breakpoint(large) {
        margin: 2rem 0;
        padding: 2rem;
    }
}

.productbox img {
    display: block;
    margin: 2rem auto;
}

.productbox img.left {
    @include breakpoint(large) {
        float: left;
        margin: 0 3rem 1.5rem 0;
    }
}

.productbox img.right {
    @include breakpoint(large) {
        float: right;
        margin: 0 0 1.5rem 3rem;   
    }
}