#newsletter_block_left {
    box-sizing: border-box;
    width: 100%;
    margin: 0 auto;
    padding: 0 .9375rem 2rem .9375rem;
    border-bottom: 1px solid rgba(1,22,30, .15);
    text-align: center;

    @include breakpoint(large) {
        padding: 0 1.6875rem 2rem 1.6875rem;
    }

    .title_block {
        color: #4C84FF;
        font-size: 1.5rem;
        font-weight: 600;
    }

    .block_content {
        width: 100%;
        max-width: 31.25rem;
        margin: 0 auto;
        padding: 1rem 0;
        text-align: center;
    }

    .block_content p {
        display: flex;
        flex-direction: row;
    }

    .inputNew {
        transition: background-color 0.25s ease-in-out 0s, border-color 0.25s ease-in-out 0s;
        box-sizing: border-box;
        height: 3.5rem;
        margin: 0;
        padding: 0.5rem 1rem;
        background: #fff;
        border: 1px solid rgba(1,22,30, .15);
        border-radius: 3.5rem 0 0 3.5rem;
        border-right: 0;
        color: #01161E;
        font-size: 1rem;
        font-family: "Source Sans Pro",sans-serif;
        outline: none;

        &:focus {
            outline: none;
        }
    }

    .button_mini {
        transition: background-color 0.3s cubic-bezier(.2,.57,.73,.95) 0s, color 0.3s cubic-bezier(.2,.57,.73,.95) 0s;
        box-sizing: border-box;
        width: 4rem;
        height: 3.5rem;
        background: #F15173;
        border: 1px solid rgba(255,255,255,0.2);
        border-left: 0;
        border-radius: 0 3.5rem 3.5rem 0;
        color: #fff;
        text-align: center;
        font-family: "Source Sans Pro",sans-serif;
        outline: none;
        text-transform: uppercase;
        font-weight: 600;
        cursor: pointer;

        &:hover {
            background: darken(#F15173, 20%);
            color: #fff;
        }
    }

    .block_subtitle {
        max-width: 103rem;
        margin: 0 auto;
    } 
}