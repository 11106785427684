.benefit-item{
    flex-direction: row;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 3;
    svg{
        fill: $f-benefits-icon-color;
        flex: 0 0 auto;
        width: $fi-social-icons-size;
        height: $fi-social-icons-size;
    }
    p{
        flex: 1 1 auto;
        margin-bottom: 0;
        @extend %mediumFontSize;
    }
    .productInfoTable &{
        svg{
            fill: $p-benefits-icon-color;
            width: $fi-social-icons-size;
            height: $fi-social-icons-size;
        }
        p{
            @extend %smallFontSize;
        }
    }
    @include breakpoint(medium){
        flex-direction: column;
        p{
            text-align: center;
        }
    }
    @include breakpoint(xlarge){
        flex-direction: row;
        p{
            text-align: left;
        }
    }
}
.iconLink{
    padding-left: 0;
    display: inline-flex;
    align-items: center;
    line-height: normal;
    @extend %baseMarginRightThird;
    &:before{
        display: none;
    }
    svg{
        width: 2rem;
        height: 2rem;
        margin: 0 1rem 0 0;

        &.tiny{
            width: $fi-social-icons-size/2;
            height: $fi-social-icons-size/2;
        }
    }
    &:hover{
        text-decoration: underline;
    }
    &.primary{
        svg{
            fill: map_get($foundation-palette, primary);
        }
    }
    &.secondary{
        svg{
            fill: #cc4b37;
        }
    }
}

span.iconLink {
    &:hover{
        text-decoration: none;
    }
}

