.pagination li {
    border-radius: 1.875rem;
}

.pagination a,
.pagination button {
    transition: background-color 0.3s cubic-bezier(.2,.57,.73,.95) 0s, color 0.3s cubic-bezier(.2,.57,.73,.95) 0s;
    border-radius: 1.875rem;
    color: #4C84FF;
    text-decoration: none;
}

.pagination .current {
    background-color: #4C84FF;
    color: #fff;
}