.button{
    @extend %baseMarginBottomHalf;
    @extend %normalFontSize;
    font-weight: bold;
    svg{
        display: inline-flex;
        vertical-align: middle;
        margin: $fbutton-svg-margin;
        height: $fbutton-svg-size;
        width: $fbutton-svg-size;
        fill: $white;
    }
    &.sort{
        text-align: center;

        &:before{
            content: "";
            display: block;
            margin: 0 auto;
            align-self: center;
            width: $pcounter-arrow-size;
            height: $pcounter-arrow-size;
            background: setColorAngle(#fff) center center;
            background-size: $pcounter-arrow-size $pcounter-arrow-size;
            transform: rotate($pcounter-arrow-rotate);
        }
        &.sortDown{
            border-right: 0;
            border-radius: 1.375rem 0 0 1.375rem;
        }
        &.sortUp{
            border-left: 0;
            border-radius: 0 1.375rem 1.375rem 0;
            
            &:before{
                transform: rotate($pcounter-arrow-rotate-up);
            }
        }

        &:hover,
        &:focus {
            background-color: #ffde00;
        }
    }
    &.hollow{
        &:hover{
            text-decoration: underline;
        }
    }
    @each $color, $value in $foundation-palette {
        &.#{$color}.hollow{
            color: #{$color};
        }
    }
}
[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea{
    @extend %baseMarginBottomHalf;
    @extend %normalFontSize;
}
@if ($button-radius) {
    [type='button']{
        border-radius: $button-radius;
    }
}