.box {
    margin: 0 0 1rem 0;
    padding: 1rem;
    background: #3c454e;
    color: #939CA7;

    @include breakpoint(large) {
        margin-bottom: 2rem;
        padding: 2rem 2rem 1.5rem 2rem;
    }
}

.box h1,
.box h2,
.box h3,
.box h4,
.box h5,
.box h6 {
	color: #D3DBE5;
}

.box a {
	color: #fff;

    &:hover,
    &:focus,
    &:active {
        color: #ffde00;
    }
}