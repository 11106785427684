.filter {
    box-sizing: border-box;
    position: relative;
    z-index: 8;

    &:hover {
        z-index: 9;
    }
}

.filter__main {
    position: relative;
    z-index: 8;
    margin: 0 0 1.5rem 0;
    border: 1px solid rgba(37,37,37,0.1);
}

.filter__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 1rem 1rem 0.5rem 1rem;
    background: rgba(244,245,249,0.7);
}

.filter__data,
.layered_price {
    position: relative;
    z-index: 8;
    width: calc(50% - 0.5rem);
    margin: 0 0 0.5rem 0;

    @include breakpoint(large) {
        width: calc(33.333% - 0.5rem);
    }

    &:after {
        content: '';
        position: absolute;
        top: 1.5rem;
        left: 0;
        display: block;
        width: 100%;
        height: 0.25rem;
        background: rgba(0,0,0, 0);
    }

    &:hover {
        z-index: 9;

        .filter__menu {
            visibility: visible;
            opacity: 1;
        }
    }
}

.filter__col {
    margin: 0;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    background: #fff;
    border: 1px solid rgba(37,37,37,0.1);
    color: rgba(37,37,37,0.8);
    font-size: 0.875rem;
}

.filter__trigger {
    position: relative;
    display: block;
    padding: 0 1.5rem 0 0;
    font-weight: 700;
    cursor: pointer;
}

.filter__arrow {
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 1.5rem;
    height: 1.5rem;

    &:before {
        box-sizing: border-box;
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(135deg);
        width: 0.5rem;
        height: 0.5rem;
        margin-top: -0.125rem;
        border-style: solid;
        border-color: rgba(37,37,37,0.8);
        border-width: 1px 1px 0 0;
    }
}

.filter__menu {
    transition: all 0.3s linear 0s;
    box-sizing: border-box;
    //overflow: auto;
    position: absolute;
    top: 2.375rem;
    left: 0;
    width: 17.5rem;
    //max-height: 17.5rem;
    background: #fff;
    border: 1px solid rgba(37,37,37,0.1);
    box-shadow: 0 1px 4px 0 rgba(0,0,0, 0.2);
    //-webkit-overflow-scrolling: touch;
    visibility: hidden;
    opacity: 0;

    ul {
        margin: 0;
        padding: 0.5rem 0;
        list-style: none;
    }

    li {
        padding: 0.3125rem 0;
    }

    a {
        color: #252525;
        text-decoration: none;
    }

    .row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        min-width: 100%;
    }

    .col-xs-6 {
        box-sizing: border-box;
        width: 50%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 0 0.5rem;
        font-size: .9375rem;
        line-height: 2.4375rem;

        input[type=text] {
            width: 50%;
            margin: 0 0.5rem;
            text-align: center;
            font-size: 1rem;
        }
    }
}

.filter__label {
    position: relative;
    display: block;
    padding: 0 0.75rem 0 2rem;
    color: #252525;
    font-size: 0.9375rem;
    line-height: 1.3;

    input {
        position: absolute;
        top: 0.125rem;
        left: 0.75rem;
        margin: 0;
        padding: 0;
    }
}

.filter__loader {
    box-sizing: border-box;
    display: none;
    width: 100%;
    min-width: 100%;
    padding: 1rem;
    color: #747474;
    text-align: center;
}

.filter__loader.is-active {
    display: block;
}

.filter__loading {
    display: flex; 
    flex-direction: row;
    width: 3.125rem;
    margin: 1rem auto;
}

.filter__loading > div {
    width: 2px;
    height: 1.5625rem;
    margin: 2px 4px;
    background: rgba(255,255,255, 0.6);
    border-radius: 2px;
    animation-fill-mode: both;
}

.filter__loading > div:nth-child(1) {
    animation: loader 1s -0.4s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
}

.filter__loading > div:nth-child(2) {
    animation: loader 1s -0.3s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
}

.filter__loading > div:nth-child(3) {
    animation: loader 1s -0.2s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
}

.filter__loading > div:nth-child(4) {
    animation: loader 1s -0.1s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
}

.filter__loading > div:nth-child(5) {
    animation: loader 1s 0s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08)
}

@keyframes loader {
    0% {
        transform: scaley(1);
    }
    50% {
        transform: scaley(0.4);
    }
    100% {
        transform: scaley(1);
    }
}

.filter__meta {
    display: none;
    flex-direction: column;
    margin: 0!important;
    padding: 0!important;

    @include breakpoint(large) {
        flex-direction: row;
    }
}

.filter__meta.is-active {
    display: flex;
}

.filter__aside {
    box-sizing: border-box;
    width: 100%;
    padding: 0.5rem 1rem; 

    @include breakpoint(large) {
        width: 8.75rem;
    }
}

.filter__content {
    box-sizing: border-box;
    width: 100%;
    padding: 0;
    
    @include breakpoint(large) {
        width: calc(100% - 8.75rem);
    } 
}

.filter__legend {
    display: block;
    color: #747474;
    font-size: 0.9375rem;
}

.filter__dfn {
    ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        list-style: none;
    }

    li {
        padding: 0.5rem 1rem 0.5rem 0;
    }
}

.filter__remove {
    transition: border-color 0.3s linear 0s, color 0.3s linear 0s;
    position: relative;
    z-index: 1;
    display: block;
    padding: 0.25rem 1.75rem 0.25rem 0.75rem;
    background: #404040;
    border-radius: 0.815rem;
    color: #fff;
    line-height: 1.2;
    font-size: 0.9375rem;
    text-decoration: none;

    &:hover,
    &:focus {
        color: #ff0000;
    }
}

.filter__close {
    position: absolute;
    top: 50%;
    right: 0.5rem;
    transform: translateY(-50%);
    color: #ff0000;
    font-size: 1.25rem;
}

.filter__clear {
    transition: border-color 0.3s linear 0s, color 0.3s linear 0s;
    display: block;
    color: #ff0000;
    font-size: 0.9375rem;
    outline: none;

    &:hover,
    &:focus {
        color: #747474;
    }
}

#enabled_filters {
    &:before,
    &:after {
        content: '';
        display: table;
    }

    &:after {
        clear: both;
    }
}

.filter__help {
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    width: 1rem;
    height: 1rem;
    margin: 0 0 0 .3125rem;
    border-radius: 50%;
    background: #ffe500;
    color: #000;
    text-align: center;
    line-height: 1rem;
    font-size: 0.875rem;
    cursor: pointer;
    font-weight: 400;

    &:before {
        transition: all 0.3s linear 0s;
        box-sizing: border-box;
        content: attr(data-help-text);
        position: absolute;
        bottom: 1.25rem;
        left: 50%;
        transform: translateX(-50%);
        width: 12.5rem;
        padding: 0.5rem 1rem;
        background: #000;
        box-shadow: 0 1px 4px 0 rgba(0,0,0, 0.2);
        color: #fff;
        opacity: 0;
        visibility: hidden;
        font-weight: 400;
    }

    &:after {
        content: '?';
    }

    &:hover {
        &:before {
            opacity: 1;
            visibility: visible;
        }
    }
}

