%header1FontSize{
    @include poly-fluid-sizing('font-size', $header1-font-size);
}
%header2FontSize{
    @include poly-fluid-sizing('font-size', $header2-font-size);
}
%header3FontSize{
    @include poly-fluid-sizing('font-size', $header3-font-size);
}
%smallFontSize{
    @include poly-fluid-sizing('font-size', $smaller-font-size);
    @include poly-fluid-sizing('line-height', getMapMulti($smaller-font-size,1.4));
}
%normalFontSize{
    @include poly-fluid-sizing('font-size', $normal-font-size);
    @include poly-fluid-sizing('line-height', getMapMulti($normal-font-size,1.5));
}
%mediumFontSize{
    @include poly-fluid-sizing('font-size', $medium-font-size);
    @include poly-fluid-sizing('line-height', getMapMulti($medium-font-size,1.6));
}
%bigFontSize{
    @include poly-fluid-sizing('font-size', $big-font-size);
    @include poly-fluid-sizing('line-height', getMapMulti($big-font-size,1.6));
}
%baseMarginBottom{
    @include responsive-size(margin-bottom, $grid-margin-gutters);
}
%baseMarginBottomHalf{
    @include responsive-size(margin-bottom, getMapDiv($grid-margin-gutters, 2));
}
%baseMarginBottomThird{
    @include responsive-size(margin-bottom, getMapDiv($grid-margin-gutters, 3));
}
%baseMarginBottomFourth{
    @include responsive-size(margin-bottom, getMapDiv($grid-margin-gutters, 4));
}
%basePadding{
    @include responsive-size(padding, $grid-margin-gutters);
}
%basePaddingHalf{
    @include responsive-size(padding, getMapDiv($grid-margin-gutters, 2));
}
%basePaddingThird{
    @include responsive-size(padding, getMapDiv($grid-margin-gutters, 3));
}
%basePaddingFourth{
    @include responsive-size(padding, getMapDiv($grid-margin-gutters, 4));
}
%basePaddingBottom{
    @include responsive-size(padding-bottom, $grid-margin-gutters);
}
%basePaddingBottomHalf{
    @include responsive-size(padding-bottom, getMapDiv($grid-margin-gutters, 2));
}
%basePaddingBottomThird{
    @include responsive-size(padding-bottom, getMapDiv($grid-margin-gutters, 3));
}
%basePaddingBottomFourth{
    @include responsive-size(padding-bottom, getMapDiv($grid-margin-gutters, 4));
}
%baseMarginTop{
    @include responsive-size(margin-top, $grid-margin-gutters);
}
%baseMarginTopHalf{
    @include responsive-size(margin-top, getMapDiv($grid-margin-gutters, 2));
}
%baseMarginTopThird{
    @include responsive-size(margin-top, getMapDiv($grid-margin-gutters, 3));
}
%baseMarginTopFourth{
    @include responsive-size(margin-top, getMapDiv($grid-margin-gutters, 4));
}
%baseMarginLeft{
    @include responsive-size(margin-left, $grid-margin-gutters);
}
%baseMarginLeftThird{
    @include responsive-size(margin-left, getMapDiv($grid-margin-gutters, 3));
}
%baseMarginRight{
    @include responsive-size(margin-right, $grid-margin-gutters);
}
%baseMarginRightHalf{
    @include responsive-size(margin-right, getMapDiv($grid-margin-gutters, 2));
}
%baseMarginRightThird{
    @include responsive-size(margin-right, getMapDiv($grid-margin-gutters, 3));
}
%baseMarginLeftNegative{
    @include responsive-size(margin-left, getMapDiv($grid-margin-gutters, -1));
}
%baseMarginRightNegative{
    @include responsive-size(margin-right, getMapDiv($grid-margin-gutters, -1));
}
%baseMarginLeftHalfNegative{
    @include responsive-size(margin-left, getMapDiv($grid-margin-gutters, -2));
}
%baseMarginRightHalfNegative{
    @include responsive-size(margin-right, getMapDiv($grid-margin-gutters, -2));
}
%baseMarginLeftThirdNegative{
    @include responsive-size(margin-left, getMapDiv($grid-margin-gutters, -3));
}
%baseMarginRightThirdNegative{
    @include responsive-size(margin-right, getMapDiv($grid-margin-gutters, -3));
}
%basePaddingTop{
    @include responsive-size(padding-top, $grid-margin-gutters);
}
%basePaddingTopHalf{
    @include responsive-size(padding-top, getMapDiv($grid-margin-gutters, 2));
}
%basePaddingTopThird{
    @include responsive-size(padding-top, getMapDiv($grid-margin-gutters, 3));
}
%basePaddingTopFourth{
    @include responsive-size(padding-top, getMapDiv($grid-margin-gutters, 4));
}
%basePaddingLeft{
    @include responsive-size(padding-left, $grid-margin-gutters);
}
%basePaddingLeftHalf{
    @include responsive-size(padding-left, getMapDiv($grid-margin-gutters, 2));
}
%basePaddingLeftThird{
    @include responsive-size(padding-left, getMapDiv($grid-margin-gutters, 3));
}
%basePaddingLeftFourth{
    @include responsive-size(padding-left, getMapDiv($grid-margin-gutters, 4));
}
%basePaddingRight{
    @include responsive-size(padding-right, $grid-margin-gutters);
}
%basePaddingRightHalf{
    @include responsive-size(padding-right, getMapDiv($grid-margin-gutters, 2));
}
%basePaddingRightThird{
    @include responsive-size(padding-right, getMapDiv($grid-margin-gutters, 3));
}
%basePaddingRightFourth{
    @include responsive-size(padding-right, getMapDiv($grid-margin-gutters, 4));
}