.home-intro {
    max-width: 75rem;
    margin: 0 auto;
    padding: 1rem 0 2rem 0;
}

.home-intro h1 {
	padding-bottom: 1rem;
	line-height: 1.2;
	text-align: center;
}