.combination {
	display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.combination__item {
	width: 1.375rem;
	height: 1.375rem;
	margin: 0.125rem;
	border-radius: 50%;
}