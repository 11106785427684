.news {

}

.news__header {
    padding: 2.5rem 0;
}

.news__headline {
    width: 100%;
    max-width: 72rem;
    margin: 0 auto;
    padding: 0 .9375rem;

    @include breakpoint(large) {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }

    h1,
    h2 {
        margin: 0;
        padding: 0;
    }
}

.news__list {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 52rem;
    margin: 0 auto;
    padding: 0 .9375rem;

    @include breakpoint(large) {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }
}

.news__item {
    display: flex;
    flex-direction: column;
    padding: 2rem 0;
    border-top: 1px solid #dbdbdb;

    @include breakpoint(large) {
        flex-direction: row;
        padding: 3rem 0;
    }

    &:first-child {
        border-top: 0;
    }
}

.news__img {
    text-align: center;
    padding: 0 0 1.5rem 0;

    @include breakpoint(large) {
        width: 24rem;
        padding: 0 0 0 2rem;
    }
}

.news__article {
    @include breakpoint(large) {
        order: -1;        
    }

    h2 {
        color: #2a3e92;
    }

    a {
        border-bottom: 1px solid transparent;
        color: #2a3e92;

        &:hover,
        &:focus,
        &:active {
            border-bottom-color: rgba(42, 62, 146, 0.3);
        }
    }
}