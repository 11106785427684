.p-banner {
    box-sizing: border-box;
    display: flex;
    height: 16.25rem;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 0 2rem 0;
    padding: 1rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;

    @include breakpoint(large) {
        height: 34.375rem;
        padding: 2rem;
    }

    p {
        font-size: 1.5rem;
        line-height: 1.1;

        @include breakpoint(large) {
            font-size: 3rem;
        }
    }

    .p-banner__txt {
        width: 70%;

        @include breakpoint(large) {
            width: 40%;
        }
    }
}

.p-banner--left {
    justify-content: flex-start;
    text-align: left;
}

.p-banner--right {
    justify-content: flex-end;
    text-align: right;
}

.p-banner--clr-white {
    color: #fff;
}