.dir {
    padding: 1rem 0 2rem 0;
}

.dir__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @include breakpoint(medium) {
        width: calc(100% + 1rem);
        margin-left: -.5rem;
    }
}

.dir__item {
    box-sizing: border-box;
    width: 100%;
    padding: 0.5rem 0;

    @include breakpoint(medium) {
        width: 50%;
        padding: 0.5rem;
    }

    @include breakpoint(xxlarge) {
        width: 33.333%;
    }
}

.dir__link {
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    padding: 0 2rem 0 0; 
    background: #fff;
    box-shadow: 0 1px 4px 0 rgba(0,0,0, 0.2);
    text-decoration: none;

    &:after {
        transition: right 0.3s cubic-bezier(.2,.57,.73,.95) 0s, border-color 0.3s cubic-bezier(.2,.57,.73,.95) 0s;
        box-sizing: border-box;
        content: '';
        position: absolute;
        top: 52%;
        right: 1rem;
        transform: translateY(-50%) rotate(45deg);
        width: 0.5rem;
        height: 0.5rem;
        margin-top: -0.125rem;
        border-style: solid;
        border-color: #F15173;
        border-width: 2px 2px 0 0;
    }

    &:hover,
    &:focus,
    &:active {
        color: #01161E;

        &:after {
            right: 0.5rem;
        }
    }
}

.dir__ico {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 5rem;
    min-width: 5rem;
    max-width: 5rem;
    height: 5rem;
    margin: 0 1rem 0 0;

    img {
        margin: 0;
        max-width: 100%;
        max-height: 5rem;
    }
}

.dir__txt {
    line-height: 1.2;
}

.dir__bdi {
    font-weight: 700;
}

.dir__item--primary {
    .dir__link {
        background: #4C84FF;
        color: #fff;

        &:after {
            border-color: #fff;
        }

        &:hover,
        &:focus,
        &:active {
            color: rgba(255,255,255, 0.9);
        }
    }
}

.dir__item--secondary {
    .dir__link {
        background: #F15173;
        color: #fff;

        &:after {
            border-color: #fff;
        }

        &:hover,
        &:focus,
        &:active {
            color: rgba(255,255,255, 0.9);
        }
    }
}