.variant {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.variant__item {
	width: 1.5rem;
    padding: 0.125rem;

    img {
    	max-height: 1.5rem;
    }
}