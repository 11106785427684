#cofis-cart {
    .checkoutSteps,
    .checkoutLine,
    .checkoutInfo,
    .checkoutDiscountCode {
        margin-left: 0;
        margin-right: 0;
    }

    .productCounter .button {
        margin-bottom: 0;
    }

    .productCounter .sortDown {
        border-radius: 1.375rem 0 0 1.375rem;
    }

    .productCounter .sortUp {
        border-radius: 0 1.375rem 1.375rem 0;
    }
}

