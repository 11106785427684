.list__item {
    position: relative;
    height: 15rem;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin: 0 0 1rem 0;
    color: #fff;

    @include breakpoint(large) {
        align-items: center;
        justify-content: space-between;
        height: auto;
        margin: 0;
        padding: 2rem;

        &:nth-child(2n) {
            .list__main {
                order: 2;
            }

            .list__ico {
                left: 0;
                right: auto;
            }
        }
    }
}

.list__main {
    order: -1;
    width: 70%;
    padding: 1rem 0 1rem 1rem;

    @include breakpoint(large) {
        width: 45%;
        padding: 0 0 0 1rem;
    }

    h2 {
        color: #fff;
        font-size: 1.875rem;
        line-height: 1.1;

        @include breakpoint(large) {
            font-size: 2.5rem;
        }
    }
}

.list__fig {
    width: 30%;

    @include breakpoint(large) {
        width: 50%;
    }
}

.list__deco {
    display: none;

    @include breakpoint(large) {
        display: block;
    }

    img {
        box-shadow: 0px 0px 20px -5px rgba(0,0,0, 0.6);
    }
}

.list__article p {
    display: none;

    @include breakpoint(large) {
        display: block;
    }
}

.list__meta {
    display: none;

    @include breakpoint(large) {
        display: block;
    }
}

.list__ico {
    box-sizing: border-box;
    overflow: hidden;
    text-align: center;
    height: 15rem;
    padding: 5rem 0 0 0;

    @include breakpoint(large) {
        position: absolute;
        right: 0;
        top: 2rem;
        width: 50%;
        height: calc(100% - 4rem);    
        padding: 0;
    }

    img {
        max-height: 100%;
    }
}

.list__btn {
    transition: background-color 0.25s ease-out 0s, color 0.25s ease-out 0s;
    display: inline-block;
    margin: 0.5rem 0;
    padding: 0.5rem;
    border: 1px solid #fff;
    color: #fff;
    font-size: 90%;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
        background-color: #fff;
        color: #000;
    }

    @include breakpoint(large) {
        margin: 1rem 0;
         padding: 0.5rem 1rem;
    }
}

.list__meta {
    padding: 1rem 0 0 0;

    h2 {
        font-size: 1.25rem;
        text-transform: uppercase;
    }
}

.list__imgs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.list__img {
    width: 15vw;
    padding: 1rem 1rem 1rem 0;
}

.list__link {
    position: absolute;
    z-index: 2;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;

    @include breakpoint(large) {
        display: none;
    }
}