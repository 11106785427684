.photogal ul {
	margin: 0;
	padding: 0;
}

.photogal .pure-js-lightbox-container li {
	height: auto;
}

.photogal li a {
	overflow: hidden;
	position: relative;
	display: block;
	height: 0;
	padding-bottom: 56.25%;
}

.photogal li img {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.photogal .subtitle {
	background: #000!important;
	font-family: $sub-font;
}

