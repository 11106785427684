@function linear-interpolation($map) {
    $keys: map-keys($map);
    @if (length($keys) != 2) {
        @error "linear-interpolation() $map must be exactly 2 values";
    }
    // The slope
    $m: (map-get($map, nth($keys, 2)) - map-get($map, nth($keys, 1)))/(nth($keys, 2) - nth($keys,1));

    // The y-intercept
    $b: map-get($map, nth($keys, 1)) - $m * nth($keys, 1);

    // Determine if the sign should be positive or negative
    $sign: "+";
    @if ($b < 0) {
        $sign: "-";
        $b: abs($b);
    }

    @return calc(#{$m*100}vw #{$sign} #{$b});
}

@function map-sort($map) {
    $keys: list-sort(map-keys($map));
    $sortedMap: ();
    @each $key in $keys {
        $sortedMap: map-merge($sortedMap, ($key: map-get($map, $key)));
    }
    @return $sortedMap;
}

@function list-sort($list) {
    $sortedlist: ();
    @while length($list) > 0 {
        $value: nth($list,1);
        @each $item in $list {
            @if $item < $value {
                $value: $item;
            }
        }
        $sortedlist: append($sortedlist, $value, 'space');
        $list: list-remove($list, index($list, $value));
    }
    @return $sortedlist;
}

@function list-remove($list, $index) {
    $newList: ();
    @for $i from 1 through length($list) {
        @if $i != $index {
            $newList: append($newList, nth($list,$i), 'space');
        }
    }
    @return $newList;
}

@function getMapDiv($map, $divider) {
    $newMap : ();
    @each $size, $value in $map {
        $result: ($value / $divider);
        $result: rem-calc($result);
        $mmap: ($size: $result);
        $newMap : map-merge($newMap, $mmap);
    }
    @return $newMap;
}
@function getMapMulti($map, $multiplier) {
    $newMap : ();
    @each $size, $value in $map {
        $result: ($value * $multiplier);
        $result: rem-calc($result);
        $mmap: ($size: $result);
        $newMap : map-merge($newMap, $mmap);
    }
    @return $newMap;
}
@function is-map($var){
    @return type-of($var) == 'map';
}

@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);
    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }
    @return $string;
}

@function url-encode($string) {
    $map: (
            "%": "%25",
            "<": "%3C",
            ">": "%3E",
            " ": "%20",
            "!": "%21",
            "*": "%2A",
            "'": "%27",
            '"': "%22",
            "(": "%28",
            ")": "%29",
            ";": "%3B",
            ":": "%3A",
            "@": "%40",
            "&": "%26",
            "=": "%3D",
            "+": "%2B",
            "$": "%24",
            ",": "%2C",
            "/": "%2F",
            "?": "%3F",
            "#": "%23",
            "[": "%5B",
            "]": "%5D"
    );
    $new: $string;
    @each $search, $replace in $map {
        $new: str-replace($new, $search, $replace);
    }
    @return $new;
}
@function inline-svg($string) {
    @return url('data:image/svg+xml;charset=utf-8,#{url-encode($string)}');
}
@function setColorAngle($color) {
    @return inline-svg('<svg version="1.1" id="Vrstva_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="100px" height="100px" viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve"><path id="Path_517" fill="' + $color + '" d="M71.3,49.3l-34.1-34c-0.3-0.4-0.9-0.4-1.2-0.1c0,0-0.1,0-0.1,0.1l-7.5,7.5L55,49.3c0.3,0.4,0.3,1,0,1.4 L28.4,77.2l7.5,7.5c0.3,0.4,0.9,0.4,1.2,0.1c0,0,0.1-0.1,0.1-0.1l34.1-34C71.7,50.3,71.7,49.7,71.3,49.3z"/></svg>');
}

@function z-index($key1, $key2: null) {
    $map: $z-indexes;
    $found-index: null;
    @if (map-has-key($map, $key1)) {
        $found-index: index(map-keys($map), $key1);
        @if ($key2) {
            $map: map-get($map, $key1);
            $found-index: index($map, $key2);
        }
    }
    @return $found-index;
}

@function z($key1, $key2: null) {
    @return z-index($key1, $key2);
}