@mixin responsive-size($property, $s-map, $s-breakpoints: $breakpoints, $design-philosophy: min-width) {
    @each $s-breakpoint, $s-size in $s-map {
        @if type-of($s-size) == 'number' and is-absolute-length($s-size) {
            $s-size: rem-calc($s-size);
        }
        @if $s-breakpoint == null {
            #{$property}: $s-size;
        }
        @else {
            @if map-has-key($s-breakpoints, $s-breakpoint) {
                $s-breakpoint: map-get($s-breakpoints, $s-breakpoint);
            }
            @media screen and (#{$design-philosophy}: $s-breakpoint) {
                #{$property}: $s-size;
            }
        }
    }
}

@mixin poly-fluid-sizing($property, $map) {
    // Get the number of provided breakpoints
    $length: length(map-keys($map));

    // Error if the number of breakpoints is < 2
    @if ($length < 2) {
        @error "poly-fluid-sizing() $map requires at least values"
    }

    // Sort the map by viewport width (key)
    $map: map-sort($map);
    $keys: map-keys($map);

    // Minimum size
    #{$property}: map-get($map, nth($keys,1));

    // Interpolated size through breakpoints
    @for $i from 1 through ($length - 1) {
        @media (min-width:nth($keys,$i)) {
            $value1: map-get($map, nth($keys,$i));
            $value2: map-get($map, nth($keys,($i + 1)));
            // If values are not equal, perform linear interpolation
            @if ($value1 != $value2) {
                #{$property}: linear-interpolation((nth($keys,$i): $value1, nth($keys,($i+1)): $value2));
            } @else {
                #{$property}: $value1;
            }
        }
    }

    // Maxmimum size
    @media (min-width:nth($keys,$length)) {
        #{$property}: map-get($map, nth($keys,$length));
    }
}

@mixin scrimGradient($direction: 'to bottom', $startColor: 'black') {
    $scrimCoordinates: (
            0: 1,
            19: 0.738,
            34: 0.541,
            47: 0.382,
            56.5: 0.278,
            65: 0.194,
            73: 0.126,
            80.2: 0.075,
            86.1: 0.042,
            91: 0.021,
            95.2: 0.008,
            98.2: 0.002,
            100: 0
    );

    $hue: hue($startColor);
    $saturation: saturation($startColor);
    $lightness: lightness($startColor);
    $stops: ();

    @each $colorStop, $alphaValue in $scrimCoordinates {
        $stop: hsla($hue, $saturation, $lightness, $alphaValue) percentage($colorStop/100);
        $stops: append($stops, $stop, comma);
    }

    background: linear-gradient(unquote($direction), $stops);

}

@mixin responsive-generator-class-content($property-name, $s-breakpoints: $breakpoints) {
    @each $size, $value in $s-breakpoints {
        &.#{$size}-#{$property-name} {
            @media screen and (#{"min-width"}: $value) {
                @content;
            }
        }
    }
}