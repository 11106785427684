/*@font-face {
    font-family: 'montserrat';
    src: url('../fonts/Montserrat-medium.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'montserrat';
    src: url('../fonts/montserrat-regular-webfont.eot');
    src: url('../fonts/montserrat-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/montserrat-regular-webfont.woff2') format('woff2'),
    url('../fonts/montserrat-regular-webfont.woff') format('woff'),
    url('../fonts/montserrat-regular-webfont.ttf') format('truetype'),
    url('../fonts/montserrat-regular-webfont.svg#montserratbold') format('svg');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'montserrat';
    src: url('../fonts/montserrat-light-webfont.eot');
    src: url('../fonts/montserrat-light-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/montserrat-light-webfont.woff2') format('woff2'),
    url('../fonts/montserrat-light-webfont.woff') format('woff'),
    url('../fonts/montserrat-light-webfont.ttf') format('truetype'),
    url('../fonts/montserrat-light-webfont.svg#montserratbold') format('svg');
    font-weight: normal;
    font-style: normal;
}*/
.topMenu{
     @include breakpoint(large) {
         &:after {
             border-color: mix($tm-border-color, $white, 50%);
             z-index: 1;
         }
         &:before {
             width: 100%;
             height: 100%;
             left: 0;
             top: 0;
             position: absolute;
             display: block;
             content: "";
             // background: url("../img/header-img.jpg") no-repeat;
             //background-size: cover;
             //transform: translateY(-100%) translateY($tm-base-height) translateZ(0);
             z-index: -1
         }
         /*@supports (-webkit-filter: blur(5px)) or (filter: blur(5px)) {
             &:before {
                 filter: blur(5px);
             }
         }*/
     }
}
.menu {
    @include breakpoint(large){
        ul{
            position: relative;
            &:before{
                background: $tm-background;
            }
        }
/*        @supports (-webkit-filter: blur(5px)) or (filter: blur(5px)) {
            ul {
                &:before {
                    background: none;
                }
            }
        }*/
    }
}
.benefits{
    /*&:before, &:after{
        width: 106%;
        height: 206%;
        left: -3%;
        top: 60%;
        position: absolute;
        display: block;
        content: "";
        background: url("../img/header-img.jpg") no-repeat;
        background-size: cover;
        transform: translateY(-100%) translateY($tm-base-height) translateZ(0);
        z-index: 1
    }*/
    @extend %basePaddingTopThird;
    &:after{
        background: $f-benefits-background;
        opacity: 0.3;
        z-index: 2;
    }
    /*@supports (-webkit-filter: blur(5px)) or (filter: blur(5px)) {
        &:before {
            filter: blur(5px);
        }
    }*/
}
.benefit-item{
    @extend %baseMarginBottomThird;
    .productInfoTable &{
        margin-bottom: 0;
    }
}