.tiles {
    display: column;
    width: 100%;
    max-width: 75rem;
    margin: 0 auto 4rem auto;
    box-shadow: 0 1px 4px 0 rgba(0,0,0, 0.2);
}

.tiles__item {
    display: flex;
    flex-direction: column;
    background: rgba(244,245,249,0.7);

    @include breakpoint(xlarge) {
        flex-direction: row;
        align-items: center;
    }

    &:nth-child(2n) {
        .tiles__fig {
            @include breakpoint(xlarge) {
                order: -1;
            }
        }
    }
}

.tiles__article {
    box-sizing: border-box;
    padding: 1rem;

    @include breakpoint(xlarge) {
        width: 50%;
        padding: 1rem 5%;
    }

    h2 {
        display: inline-block;
        margin-bottom: 1rem;

        &:after {
            content: '';
            display: block;
            width: 100%;
            height: 3px;
            background: #ffe500;
        }
    }
}

.tiles__fig {
    box-sizing: border-box;

    @include breakpoint(xlarge) {
        width: 50%;
    }
}

.tiles__btn {
    @extend .comparison__btn;
}