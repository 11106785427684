/*
Product list
------------
 */
.productList {
    justify-content: flex-start;
    width: 100%;
    margin-left: 0!important;
    padding: 0 0 1rem 0;

    @media print, screen and (min-width: 30em) {
        width: calc(100% + 1rem);
        margin-left: -0.5rem!important;
    }

    .color-list-container {
        display: none!important;
    }
}

.productList__item {
    display: flex;
    flex-direction: row;
    padding: 0;
    margin-bottom: 2rem; 
    background: #fff;

    a {
        display: block;
        width: 100%;
        text-align: center;
        text-decoration: none;
    }

    .productList__img {
        padding-top: 1rem;
    }

    h2 {
        @extend %header3FontSize;
    }

    .add_to_compare {
        position: absolute;
        top: 0;
        right: 0;
        padding: 3px 5px 3px 24px;
        background: #fff;
        font-size: 14px;
        line-height: 1.2;
        text-align: left;


        input {
            position: absolute;
            top: 50%;
            left: 5px;
            margin: -6px 0 0 0;
            padding: 0;
        }
    }
}

.productList__item_new {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%!important;
    padding: 0!important;
    margin: 1rem 0;
    background: #fff;
    box-shadow: 0 1px 4px 0 rgba(0,0,0, 0.2);

    @include breakpoint(medium) {
        width: calc(50% - 1rem)!important;
        margin: 1rem 0.5rem;
        text-align: left!important;
    }

    @include breakpoint(xxlarge) {
        width: calc(33% - 1rem)!important;
    }

    a {
        text-decoration: none;
    }

    h2 {
        margin: 0;
        padding: 0 0 0.5rem 0;
        color: inherit;
        line-height: 1.2;
        font-size: 1.375rem;
        font-weight: 700;

         @include breakpoint(medium) {
            text-align: left;
         }

         a {
            transition: color 0.25s ease-in-out 0s;
          
            &:hover,
            &:focus,
            &:active {
                text-decoration: underline;
            }
        }
    }

    .add_to_compare {
        position: absolute;
        top: 0;
        right: 0;
        padding: 3px 5px 3px 24px;
        background: #fff;
        font-size: 14px;
        line-height: 1.2;
        text-align: left;


        input {
            position: absolute;
            top: 50%;
            left: 5px;
            margin: -6px 0 0 0;
            padding: 0;
        }
    }

    .button {
        width: 100%;
        margin-bottom: 0.5rem;
    }
}

.productList__box {
    width: 100%;
}

.productList__main {
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 100%;
    padding: 1rem;
}

.productList__fig {
    display: block;
    width: 100%;
    padding: 0 0 1rem 0;
    color: #1c1617;
    text-decoration: none;

}

.productList__img {
    transition: transform 0.25s ease-in-out 0s;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;

    img {
        max-width: 100%;
    }
}

.productList__txt {
    width: 100%;
}

.productList__info {
}

.productList__aside {
    position: relative;
    padding: 24px 20px 8px 20px;

    @include breakpoint(medium) {
        padding: 0;
    }

    &:before {
        content: '';
        position: absolute;
        top: 12px;
        left: 12px;
        width: calc(100% - 24px);
        height: 1px;
        background: #e7e7e7;

        @include breakpoint(medium) {
            display: none;
        }
    }
}

.productList__combination {
    display: block;
    width: 100%;
    padding: 0 0 0.5rem 0;
}

.productList__variant {
    display: block;
    width: 100%;
    padding: 0 0 0.5rem 0;
}

.productList__feature {
    color: #9b9b9b;
    padding: 0 0 1rem 0;

    p {
        margin: 0;
        padding: 0;
        font-size: 15px;
    }

    p+p {
        display: none;
    }

    .xl {
        color: #7d8284;
        font-weight: 500;
        font-size: 13px;
    }
}

.productList__attribute {
    padding: 0 0 1rem 0;

    p {
        margin: 0!important;
        padding: 0;
        color: #7d8284;
        font-weight: 500;
        font-size: 13px;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 15px;
    }

    li {
        display: inline-block;
        vertical-align: top;
        padding: 0;
        color: #000;
    }
}

.productList__meta {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    flex-wrap: wrap;
    align-self: flex-end;
    width: 100%;
}

.productList__price {
    line-height: 1.3;
    font-size: 1.0625rem;

    small {
        color: #747474;
        font-size: 0.75rem;
    }

    p {
        margin: 0;
        color: #01161E;
        font-weight: 700;
        line-height: 1.3;
    }

    .old {
        color: #0a0a0a;
        text-decoration: line-through;
        font-size: 0.75rem;
        font-weight: 400;
    }
}

.productList__btns {
    .button {
        margin: 0;
        padding: 0.55em 1em 0.55em 0.5em;
        white-space: nowrap;
    }
}

.productList__cat {
    display: block;
    color: #404040;
    font-style: italic;
    font-size: 0.75rem;

    a {
        transition: color 0.25s ease-in-out 0s;
        color: #404040;
       
        &:hover,
        &:focus,
        &:active {
            color: #00a4ef;
        } 
    }
}

/*
Categories list
---------------
 */
.categorytItem{
    border: $cl-border-style;
    border-width: $cl-border-width;
    @extend %baseMarginBottomHalf;
    a{
        display: flex;
        flex-direction: column;
        @extend %basePaddingLeftThird;
        @extend %basePaddingRightThird;
        @extend %basePaddingTopThird;
        &:hover{
            text-decoration: underline;
        }
    }
    img{
        @extend %baseMarginBottomThird;
    }
    h2{
        color: $cl-header-color;
        font-weight: $cl-header-font-weight;
        border: $cl-border-style;
        border-width: $cl-border-width 0 0 0;
        position: relative;
        @include responsive-size("font-size", $cl-header-font-size);
        @extend %basePaddingTopThird;
        @extend %basePaddingLeftThird;
        @extend %basePaddingRight;
        @extend %baseMarginBottomThird;
        @extend %baseMarginLeftThirdNegative;
        @extend %baseMarginRightThirdNegative;
        &:after{
            content: "";
            display: block;
            width: $cl-header-arrow-size;
            height: $cl-header-arrow-size;
            background: setColorAngle($cl-header-arrow-color) center center;
            background-size: $cl-header-arrow-size $cl-header-arrow-size;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateX(-($cl-header-arrow-size/2));
        }
    }
}


/*
Product detail
--------------
 */
.attributes {
    position: absolute;
    z-index: z(products, attributes);
    left: 0;
    top: 0;
    text-align: left;
    
    strong {
        display: block;
        margin: 0 0 0.5rem 0;  
        font-size: 0.875rem;
        line-height: 1.2;
        font-weight: 400;
    }

    span {
        display: inline-block;
        padding: 0.3125rem 0.625rem 0.3125rem 0.75rem;
        color: #fff;
    }

    .label_0 {
        span {
            background: #4C84FF; // doporucujeme
        }
    }

    .label_1 {
        span {
            background: #f46e0b; // novinka   
        }
    } 

    .label_2 {
        span {
            background: #ce0000; // akce   
        }
    } 

    .label_3 {
        span {
            background: #000; // vyprodej   
        }
    } 

    .label_4 {
        span {
            background: #5caa00; // skladem   
        }
    } 

    .label_5 {
        span {
            background: violet; // neznama vlastnost bude doplneno   
        }
    } 
}

.attributes__item {
    padding-bottom: 0.3125rem; 
}

.productInfoTable{
    .row{
        @extend %baseMarginBottomFourth;
        &.last{
            margin-bottom: 0;
        }
    }
    .cell{
        @extend %normalFontSize;
        font-weight: $p-info-table-font-weight;
    }
    .button{
        margin-bottom: 0;
    }
}

.productTabs {
    padding: 0;

    .tabs-panel {
        padding: 1rem;
    }

    .hasProducts.accordion-content {
        padding-top: 0;
        padding-bottom: 0;
        @extend %basePaddingRightHalf;
        @extend %basePaddingLeftHalf;
    }

    .accordion {

    }

    .tabs {
       
    }

    .tabs-title {
        a {
            position: relative;
            color: #1c1617;
            outline: none;
            font-size: 0.8125rem;
            text-transform: uppercase;

            &:before {
                transition: height 0.3s cubic-bezier(.2,.57,.73,.95) 0s;
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 0;
                background: #4C84FF;
            }
        }
       
    }

    .tabs-title.is-active {
        a {
            background: rgba(244,245,249,0.7);
            font-weight: 700;
            text-decoration: none;
            
            &:before {
                height: 3px;
            }
        }
    }

    .accordion-title{
        position: relative;
        padding: 0.75rem 1rem;
        font-weight: 700;
        font-size: 13px;
        text-transform: uppercase;
        outline: none;
        
        &:before{
            color: $acc-title-plusminus-color;
            font-size: $acc-title-plusminus-size;
            text-align: center;
            font-family: sans-serif;
            font-weight: bold;
            height: $acc-title-plusminus-size;
            width: $acc-title-plusminus-width;
            margin-top: -$acc-title-plusminus-size/2;
            right: 0;
        }
        &:after{
            content: "";
            display: block;
            position: absolute;
            right: $acc-title-plusminus-width;
            top: 0;
            height: 100%;
            border-left: $acc-title-plusminus-border;

        }
    }
}

.category-list {
    justify-content: center;
    padding: 0 0 1rem 0;

    .productList__item_new {
        @include breakpoint(medium) {
            width: calc(33.333% - 1rem)!important;
        }

        @include breakpoint(xxlarge) {
            width: calc(25% - 1rem)!important;
        }

        @include breakpoint(xxxlarge) {
            width: calc(20% - 1rem)!important;
        }

    }
    
    .productList__link {
        position: relative;
        display: block;
        width: 100%;
        text-align: center;

        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 3px;
            background: #ffde00;
        }
    }

    .productList__img {
        padding: 1rem 0;
    }

    h2 {
        padding-bottom: 1rem;
        text-align: center;
        font-size: 1.125rem;
    }


}