.banner {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 15.625em;

    @include breakpoint(large) {
        height: 25rem;
    }
}

.banner__img {
    overflow: hidden;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: auto;
        max-width: inherit;
        height: 100%;
    }
}

.banner__article {
    box-sizing: border-box;
    position: relative;
    z-index: 2;
    width: 100%;
    max-width: 78.75rem;
    margin: 0 auto;
    padding: 0 1.25rem;
    text-shadow: 0 0 1px rgba(0,0,0,0.05), 0 1px 2px rgba(0,0,0,0.3);

    h1 {
        font-size: 2rem;
        line-height: 1.2;
        font-weight: 300;
        color: #fff;

        @include breakpoint(large) {
            font-size: 2.5rem;
        }
    }

    p {
        margin: 0;
        padding: 1rem 0 2rem 0;
        font-size: 1rem;
    }

    .xl {
        margin: 0;
        padding: 0;
        font-size: 1.6rem;
        line-height: 1.2;
        font-weight: 300;
        color: #fff;

        @include breakpoint(large) {
            font-size: 2.5rem;
        }
    }
}





