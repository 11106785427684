.js-off-canvas-overlay {
	display: none!important;
}

.menu {
    position: relative;
    z-index: 20;
    background: #4C84FF;
    font-family: $sub-font;
    display: none;

    @include breakpoint(large) {
    	display: block;
    }
}

.offCanvasOpened .menu {
	display: block;
}

.menu ul {
	margin: 0;
	padding: 0;
	list-style: none;

	@include breakpoint(large) {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		padding: 0 1rem;
	}
}

.menu ul ul {
	display: none;

	@include breakpoint(large) {
		position: absolute;
		z-index: 10;
		top: 70px;
		left: 0;
		flex-wrap: wrap;
		align-items: flex-start;
		justify-content: center;
		width: 100%;
		background: #fff;
		box-shadow: 0 2px 2px 0 rgba(0,0,0, 0.2);
	}

	@include breakpoint(xlarge) {
		top: 51px;
	}

	a {
		&:before {
			display: none;
		}
	}
}

.menu li {
	border-top: 1px solid rgba(102,102,102, 0.6);
	text-transform: uppercase;
	font-size: 1.125rem;
	font-weight: 700;

	&:first-child {
		border-top: 0;
	}

	@include breakpoint(large) {
		border-top: 0;
		font-size: 1rem;
	}
}

.menu li:hover {
	@include breakpoint(large) {
		>ul {
			display: flex;
		}
	}
}

.menu li li {
	width: 14rem;
	min-width: 14rem;
	max-width: 14rem;
	text-align: center;
}

.menu a {
	transition: background-color 0.3s cubic-bezier(.2,.57,.73,.95) 0s;
	position: relative;
	display: block;
	padding: 1rem;
	color: #fff;
	text-decoration: none;

	@include breakpoint(large) {
		text-align: center;
		line-height: 1.2;
	}

	&:hover {
		background-color: rgba(0,0,0, 0.2);
		color: #fff;
	}
}

.menu .sfHover>a {
	background-color: rgba(0,0,0, 0.2);
	color: #fff;
}

.menu__img {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 10rem;
	height: 10rem;
	margin: 0 auto;

	img {
		max-height: 10rem;
	}
}



