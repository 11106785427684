.article {
    box-sizing: border-box;
    width: 100%;
    max-width: 75rem;
    margin: 0 auto;
    padding: 0.5rem 1.25rem 0.75rem 1.25rem;

    @include breakpoint(large) {
        padding: 1rem 1.25rem 1.5rem 1.25rem;
    }
}

.article .btn {
    display: inline-block;
    padding: 0.5rem 1.5rem;
    background: #1C1617;
    color: #fff;
    line-height: 1.2;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
        background-color: #D6001F;
    }
}

.article__header {
    text-align: center;
}

.article__content {
    width: 100%;
    max-width: 52.25rem;
    margin: 0 auto;
}