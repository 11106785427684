
.others {
    
}

.others__table {
    overflow: auto;
    width: 100%;

    table {
        width: 100%;
        margin: 0;
    }

    tr,
    tbody tr:nth-child(even) {
        background: transparent;
    }

    thead,
    tbody,
    tfoot {
        background: transparent;
        border: 0;
    }

    thead {
        //@media screen and (max-width: 48em) {
        //    display: none;
        //}
    }

    th {
        border-bottom: 3px solid #f5f5f5;
        text-align: center;
        font-size: .875rem;
        line-height: 1.2;

        &:first-child {
            text-align: left;
        }

        &:last-child {
            text-align: right;
        }
    }

    tr {
        //@media screen and (max-width: 48em) {
        //    display: block;
        //    width: 100%;
        //    padding: .3125rem 0;
        //    border-bottom: 3px solid #f5f5f5;
        //}
    }


    td {
        border-bottom: 3px solid #f5f5f5;
        text-align: center;
        font-size: .9375rem;

        &:first-child {
            font-weight: 700;
            text-align: left;
        }

        &:last-child {
            text-align: right;
        }

        //@media screen and (max-width: 48em) {
        //    box-sizing: border-box;
        //    position: relative;
        //    display: block;
        //    width: 100%;
        //    padding: .3125rem 0;
        //    border-bottom: 0;
        //    text-align: right; 

        //    &:before {
        //        content: attr(data-label);
        //        position: absolute;
        //        top: 50%;
        //        left: 0;
        //        transform: translateY(-50%);
        //    }

        //    &:first-child {
        //        text-align: right;
        //    }

        //    &:last-child {
        //        .button {
        //            width: 100%;
        //        }
        //    }
        //}
    }

    .button {
        margin: 0;
        white-space: nowrap;
        font-size: .9375rem;
    }
}