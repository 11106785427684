.checkoutSteps{
    @extend %baseMarginLeftHalfNegative;
    @extend %baseMarginRightHalfNegative;
    @extend %baseMarginBottomHalf;
    ul{
        list-style: none;
        margin: 0;
    }
}
.step{
    text-align: center;
    border-bottom: $step-border-bottom;
    border-right: $step-border-right;
    &:last-of-type{
        border-right: 0;
    }
    .stepWrap{
        background: $step-background;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        @include responsive-size(height, $step-height);
        text-decoration: none;
    }
    a:hover{
        background: $step-background-hover;
    }
    p{
        margin-bottom: 0;
        flex-grow: 1;
        font-weight: $step-font-weight;
        @include responsive-size(font-size, $step-font-size);
    }
    .counter{
        flex-grow: 2;
        display: flex;
        align-items: flex-start;
        font-weight: $step-counter-font-weight;
        @extend %basePaddingTopThird;
        @extend %basePaddingLeftFourth;
        @extend %basePaddingRightFourth;
        span{
            flex-grow: 1;
            background: #4C84FF;
            border: #4C84FF;
            color: #fff;
            @extend %normalFontSize;
            @include responsive-size(width, $step-counter-size);
            @include responsive-size(height, $step-counter-size);
            @include responsive-size(line-height, $step-counter-size);
            @include responsive-size(border-radius, getMapDiv($step-counter-size,2));
        }
    }
    &.active{
        .stepWrap{
            @if ($step-active-background != false) {
                background: $step-active-background;
            }
            @if ($step-active-color != false) {
                color: $step-active-color;
            }
            &:before, &:after{
                display: block;
                //content: "";
                position: absolute;
                left: 50%;
                bottom: -$step-active-triangle-size/2;
                margin-left: -$step-active-triangle-size/2;
                width: $step-active-triangle-size;
                height: $step-active-triangle-size;
                background: $step-border-color;
                transform: rotate(45deg);
            }
            &:after{
                background: $step-background;
                bottom: (-$step-active-triangle-size/2) + rem-calc($step-border-width);
            }
        }
        .counter{
            span{
                background: #F15173;
                border: #F15173;
                color: $step-active-counter-color;
            }
        }
        a{
            &:hover{
                &:after{
                    background: $step-background-hover;
                }
            }
        }
    }
    @include breakpoint(large up){
        .stepWrap{
            flex-direction: row;
        }
        .counter{
            flex-grow: 0;
            padding-top: 0;
        }
        p{
            flex-grow: 0;
        }
    }
}
.productCounter{
    @extend %baseMarginBottomThird;
    input{
        margin-bottom: 0;
        width: $pcounter-input-width;
        height: 2.4rem;
        text-align: center;
    }
    button{
        margin-bottom: 0;
        padding: 0;
        border-radius: $pcounter-border-radius;
        display: flex;
        @include responsive-size(width, $pcounter-size)
    }
    label{
        align-self: center;
        @extend %normalFontSize;
        @extend %baseMarginRightThird;
        &.alone{
            margin-right: 0;
        }
    }
    @include breakpoint(xlarge){
        justify-content: flex-end;
    }
}
.productImage{
    @extend %basePaddingRightThird;
}
.closeButtonWrapper{
    align-self: flex-start;
    .close-button{
        position: relative;
        right: auto;
        top: auto;
        text-align: center;
        width: rem-calc(40);
        height: rem-calc(40);
        color: transparent;
        &:before{
            content: "×";
            font-size: 16px;
            font-weight: bold;
            color: #fff;
            display: block;
            position: absolute;
            background: #F15173;
            border-radius: 50%;
            line-height: $p-close-button-size;
            width: $p-close-button-size;
            height: $p-close-button-size;
            top: 50%;
            left: 50%;
            transform: translateX(rem-calc(-$p-close-button-size/2)) translateY(rem-calc(-$p-close-button-size/2));
        }
    }
}
.checkoutWrapper{
    @extend %baseMarginTopHalf;
    @extend %baseMarginBottomHalf;
    a{
        text-decoration: underline;
        &:hover{
            text-decoration: none;
        }
    }

    h2 {
        font-size: 1.5rem;
    }

    .productImage {
        align-self: center;
    }

    .redText {
        color: #000;
    }
}
.checkoutLine{
    margin: 0;
    border-top: $ch-product-line-border;
    &:last-of-type{
        border-bottom: $ch-product-line-border;
    }
    @extend %basePaddingTopThird;
    @extend %basePaddingLeftHalf;
    @extend %basePaddingRightHalf;
    @extend %baseMarginLeftHalfNegative;
    @extend %baseMarginRightHalfNegative;
    p{
        @extend %baseMarginBottomThird;
    }
}
.checkoutLine--fst {
    border-top: 0;
}
.checkoutInfo{
    border-top: $ch-prices-border;
    @extend %basePaddingTopThird;
    @extend %basePaddingLeftHalf;
    @extend %basePaddingRightHalf;
    @extend %baseMarginLeftHalfNegative;
    @extend %baseMarginRightHalfNegative;
    p{
        @extend %baseMarginBottomThird;
    }
    &.checkoutPrices{
        background: $ch-prices-background;
    }
}
.checkoutDiscountCode{
    border-bottom: $ch-discount-code-border;
    @extend %basePaddingLeftHalf;
    @extend %basePaddingRightHalf;
    @extend %baseMarginBottomHalf;
    @extend %baseMarginRightHalfNegative;
    @extend %baseMarginLeftHalfNegative;
    @extend %baseMarginRightHalfNegative;
    input[type='text']{
        @extend %baseMarginRightThird;
    }
}