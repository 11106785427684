.product__fig .thumbnails__bigpic__wrap,
.product__fig .thumbnails__bigpic {
    height: 450px;
}

@media only screen and (max-width: 470px) {
    .product__fig .thumbnails__bigpic__wrap,
    .product__fig .thumbnails__bigpic {
        height: 0;
        padding-bottom: 100%;
    }
}

.thumbnails__list__arrow:hover path {
	fill: #00a4ef!important;
}