.sidebar {
    box-sizing: border-box;
    order: 10;
    width: 100%;
    margin: 0 0 1rem 0;
    padding: 0!important;
    background: rgba(244,245,249,0.7);

    @include breakpoint(large) {
        order: -1;
        width: 17.5rem!important;
        min-width: 17.5rem!important;
        margin: 1rem 2rem 2rem 0; 
    }

    &:before,
    &:after {
        content: '';
        display: table;
    }

    &:after {
        clear: both;
    }
}

.sidebar h3 {
    box-sizing: border-box;
    position: relative;
    margin-bottom: 0;
    padding: 1rem 1rem 0.75rem 1rem;
    border-bottom: 1px solid rgba(0,0,0,0.075);
    color: #4C84FF;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1rem;
    font-family: Arial, Helvetica, sans-serif;
}

.sidebar {
    .close-button,
    #layered_block_left {
        display: none;
    }
}