.stores {
    td:nth-of-type(2) {
        font-weight: 700;
        white-space: nowrap;
    }

    a[href^="tel"],
    a[href^="sms"] {
        color: #0a0a0a;
        text-decoration: none;
    }

    thead {
        @media (max-width: 47.9375em) {
            display: none;
        }
    }

    tr {
        @media (max-width: 47.9375em) {
            border-top: 1px solid #EFEFEF;

            &:first-child {
                border-top: 0;
            }
        }
    }

    td {
        @media (max-width: 47.9375em) {
            display: block;
            width: 100%;

            &:first-child {
                text-align: center;
            }
        }
    }
}