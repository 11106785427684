.cell {
    $closest-size: null;
    @each $size, $value in $breakpoints {
        $temp-breakpoint: ($size : $value);
        @if map-has-key(getMapDiv($grid-column-gutter, 2), $size) {
            $closest-size: rem-calc(map-get(getMapDiv($grid-column-gutter, 2), $size));
        }
        @include responsive-generator-class-content(collapse, $temp-breakpoint) {
            padding-left: 0;
            padding-right: 0;
        }
        @include responsive-generator-class-content(uncollapse, $temp-breakpoint) {
            padding-left: $closest-size;
            padding-right: $closest-size;
        }
        @include responsive-generator-class-content(uncollapse-left, $temp-breakpoint) {
            padding-left: $closest-size;
        }
        @include responsive-generator-class-content(uncollapse-right, $temp-breakpoint) {
            padding-right: $closest-size;
        }
        @include responsive-generator-class-content(collapse-left, $temp-breakpoint) {
            padding-left: 0;
        }
        @include responsive-generator-class-content(collapse-right, $temp-breakpoint) {
            padding-right: 0;
        }
    }
}