.cms-list {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    list-style: none;
}

.cms-list li {
    display: flex;
    flex-direction: column;
    padding: 2rem 0;
    border-top: 1px solid #dbdbdb;

    @include breakpoint(large) {
        flex-direction: row;
        padding: 3rem 0;
    }

    &:first-child {
        border-top: 0;
    }
}

.cms-list a {
    h2 {
        color: inherit;
    }
}

.cms-gitzo-inspiruje {
    .cms-list--perex {
        width: 100%;
        display: flex;
        flex-direction: column;

        @include breakpoint(large) {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;        
        }
    }

    .cms-list--perex-image {
        text-align: center;
        padding: 0 0 1.5rem 0;

        @include breakpoint(large) {
           width: 45%;
        }
    }

    .cms-list--perex-article {
        @include breakpoint(large) {
           width: 50%;
           order: -1;
        }

        h2 {
            @include breakpoint(large) {
                font-size: 2rem;
            }
        }
    }

    .cms-list--btn {
        transition: background-color 0.25s ease-out 0s, color 0.25s ease-out 0s;
        display: inline-block;
        margin: 1rem 0;
        padding: 0.5rem 1rem;
        border: 1px solid #000;
        color: #000;
        text-decoration: none;

        &:hover,
        &:focus,
        &:active {
            background-color: #000;
            color: #fff;
        }
    }
}

.cms-ambasadori-gitzo {
    .cms-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 2rem 0;
    }

    .cms-list li {
        box-sizing: border-box;
        width: 100%;
        padding: 1rem;
        background: #fff;
        margin: 0 0 2rem 0;
        border: 0;

        @include breakpoint(large) {
            width: calc(33.333% - 1.34rem);
            margin-left: 2rem;

            &:first-child,
            &:nth-child(3n+1) {
                margin-left: 0;
            }
        }
    }

    .cms-list--perex-image {
        padding: 0 0 1rem 0;
        text-align: center;

        img {
            width: 100%;
        }
    }

    .cms-list--perex-article {
        h2 {
            font-size: 1.5rem;
            line-height: 1.2;
        }
    }

    .cms-list--btn {
        transition: background-color 0.25s ease-out 0s, color 0.25s ease-out 0s;
        display: inline-block;
        margin: 1rem 0;
        padding: 0.5rem 1rem;
        border: 1px solid #000;
        color: #000;
        text-decoration: none;

        &:hover,
        &:focus,
        &:active {
            background-color: #000;
            color: #fff;
        }
    }
}