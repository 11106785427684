.properties {
	thead,
	tbody {
		background: transparent;
		border: 0;
	}

	tbody tr:nth-child(even) {
		background: transparent;
	}
}

.properties td {
	border-bottom: 1px solid rgba(37,37,37,0.1);
}

.properties th {
	border-bottom: 1px solid rgba(37,37,37,0.1);

	@include breakpoint(large) {
		width: 40%;
	}
}