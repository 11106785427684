#attributes {
    width: 100%;
}

#attributes .attribute_fieldset {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

#attributes .attribute_label {
    box-sizing: border-box;
    width: 50%;
    margin: 0!important;
    padding: 0 1.6875rem!important;
}

#attributes .attribute_list {
    box-sizing: border-box;
    width: 50%;
    margin: 0!important;
    padding: 0 1.6875rem!important;
}

#attributes select {
    margin: 0;
    background: none;
    font-family: "Work Sans",sans-serif;
    font-weight: 400;
    font-size: 1rem;
    appearance: auto;
}


.attribute_list_radio {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; 
    align-items: center;
    justify-content: flex-end;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
        padding: 0 0 0 0.75rem;

        ::marker {
            display: none;
        }
    }

    input {
        margin: 0;
    }
}