@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@import 'helpers/functions-standalone';
@import 'helpers/mixins-standalone';

@include foundation-global-styles;
@include foundation-xy-grid-classes;
@include foundation-typography;
@include foundation-close-button;
@include foundation-button;
@include foundation-forms;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-off-canvas;
@include foundation-pagination;
@include foundation-media-object;
@include foundation-label;
@include foundation-visibility-classes;
@include foundation-flex-classes;
@include foundation-badge;
@include foundation-accordion;
@include foundation-tabs;
@include foundation-table;
@include foundation-responsive-embed;

@include motion-ui-transitions;
@include motion-ui-animations;

@import "src/css/main.scss";
@import 'helpers/placeholders';

@import 'components/forms';
@import 'components/typography';

@import 'components/layout/flexbox-grid';
@import 'components/layout/layout';
@import 'components/layout/header';
@import 'components/layout/top-menu';
@import 'components/layout/breadcrumbs';
@import 'components/layout/sidebar';
@import 'components/layout/footer';

@import 'components/other';
@import 'components/popups';
@import 'components/sliders';
@import 'components/products';
@import 'components/checkout';
@import 'components/banner';
@import 'components/article';
@import 'components/combination';
@import 'components/variant';
@import 'components/description';
@import 'components/product';
@import 'components/properties';
@import 'components/tabs';
@import 'components/colors';
@import 'components/stores';
@import 'components/badge';
@import 'components/button';
@import 'components/banners';
@import 'components/attributes';
@import 'components/product-comparison';
@import 'components/filter';
@import 'components/news';
@import 'components/category-desc';
@import 'components/category-image';
@import 'components/textBlok';
@import 'components/pictureBlok';
@import 'components/photogal';
@import 'components/cms-list';
@import 'components/twentytwenty';
@import 'components/comparison';
@import 'components/person';
@import 'components/reactive-slider';
@import 'components/pure-js-lightbox';
@import 'components/list';
@import 'components/blackbox';
@import 'components/bloktext';
@import 'components/productbox';
@import 'components/newsletter';
@import 'components/eshopmenu';
@import 'components/others';
@import 'components/cofis-cart';
@import 'components/compare-form';
@import 'components/tooltip';
@import 'components/tiles';
@import 'components/box';
@import 'components/home-intro';
@import 'components/pagination';
@import 'components/dir';
@import 'components/p-banner';
@import "project-custom";